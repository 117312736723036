import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import { Box, Button, Typography } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { EmptyListPlaceholder } from "../../components/shared/EmptyListPlaceholder";
import { useHealthRecord } from "../../hooks/useHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { ShareWithProForm } from "../../components/sharing/ShareWithProForm";
import { ShareWithCitizenForm } from "../../components/sharing/ShareWithCitizenForm";
import { GrantOwnershipForm } from "../../components/sharing/GrantOwnershipForm";
import { useAuthenticatedUser } from "../../hooks";
const Share = () => {
  const { t } = useI18n();
  const { pathname } = useLocation();
  const { id } = useParams() as { id: string };

  const currentUser = useAuthenticatedUser();
  const { refetch, healthRecord } = useHealthRecord(id);
  return (
    <Box>
      <Box sx={{ mb: 2 }}>
        <Button
          startIcon={<ArrowBackIcon sx={{ width: 24, height: 24 }} />}
          size="large"
          sx={{ pl: 0 }}
          component={Link}
          to={pathname.replace("/share", "/shares")}
        >
          <Typography variant="h6">{t("common.cta.back")}</Typography>
        </Button>
      </Box>
      {healthRecord && (!healthRecord?.firstNames || !healthRecord.lastName) ? (
        <CantShare />
      ) : (
        <>
          <ShareWithProForm onSubmitSuccessful={refetch} />
          <ShareWithCitizenForm healthRecordId={id} />
          {healthRecord && currentUser && healthRecord.ownerId == currentUser.id && (
            <GrantOwnershipForm healthRecordId={id} />
          )}
        </>
      )}
    </Box>
  );
};

export default Share;

const CantShare = () => {
  const { t } = useI18n();
  const { id } = useParams() as { id: string };
  return (
    <EmptyListPlaceholder
      title={t("share.identity_error.title")}
      icon={<PersonAddIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
      button={
        <Button
          size="large"
          component={Link}
          to={`/health-records/${id}/edit`}
          variant="contained"
          disableElevation
          data-testid="go-to-edit-health-record"
        >
          {t("share.identity_error.cta")}
        </Button>
      }
    >
      {t("share.identity_error.description")}
    </EmptyListPlaceholder>
  );
};
