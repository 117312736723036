import { Services } from ".";
import { ActionResult } from "../actionResult";
import { AppRouter } from "../appConfig";
import { ResultNotifier } from "../resultNotifier";
import { I18nKey } from "../ui/providers/I18nProvider";
import { ServicePayloadType, ServiceReturnType } from "./service";

export class ServiceBus {
  constructor(
    private readonly services: Services,
    private readonly resultNotifier: ResultNotifier,
    private readonly router: AppRouter
  ) {}

  async dispatch<K extends keyof Services>(
    message: { type: K } & ServicePayloadType<Services[K]>
  ): Promise<ActionResult<ServiceReturnType<Services[K]>>> {
    const { type, ...payload } = message;
    console.debug(`Calling service ${type} with payload`, payload);

    try {
      const result = await this.services[type].call(payload);
      console.debug(`Service ${type} returned`, result);
      if (result) {
        if (result.type === "success" && result.navigateTo) {
          console.debug("Navigating to", result.navigateTo);

          // We need to pass fromRouteId to navigate() to enable relative navigation
          //
          // Otherwise, calls like navigate("..") don't work as expected
          const matches = this.router.state.matches;
          this.router.navigate(result.navigateTo, {
            fromRouteId: matches && matches.length > 0 ? matches[matches.length - 1].route.id : undefined,
          });
        }
        this.resultNotifier.call(result);
      }
      return result ? result : { type: "success", value: undefined };
    } catch (error) {
      const errorKey: I18nKey = "notifications.unmanaged_error";
      console.log("Error while calling service", error);
      const actionResult = { type: "error", message: errorKey } as const;
      this.resultNotifier.call(actionResult);
      return actionResult;
    }
  }
}
