import { Box, Paper, Typography } from "@mui/material";
import { useI18n } from "../../../hooks/useI18n";
import { theme } from "../../../layout/Theme";
import { NotesList } from "./NotesList";

export function Notes() {
  const { t } = useI18n();

  return (
    <Box mb={4}>
      <Typography
        fontWeight="bold"
        sx={{ overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap", mb: 2 }}
      >
        {t("notes.title")}
      </Typography>
      <Paper
        sx={{
          overflow: "hidden",
          backgroundColor: "background.paper",
          border: `solid 1px ${theme.palette.neutral[200]}`,
          maxWidth: "900px",
          p: 2
        }}
        elevation={0}
      >
        <NotesList />
      </Paper>
    </Box>
  )
}