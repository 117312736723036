import { useParams } from "react-router-dom";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useArianeApi } from "../../../hooks/useArianeApi";
import { useCallback, useEffect, useState } from "react";
import { Professional, Team } from "@syadem/ariane-js";
import { Box, Divider, Grid, Typography } from "@mui/material";
import { useI18n } from "../../../hooks/useI18n";
import { theme } from "../../../layout/Theme";
import { Note } from "@syadem/kairos-citizen-js";
import { formatDistanceToNow } from "../../../../utils/dayjs";

export function NotesList() {
  const { id } = useParams() as { id: string };
  const { healthRecord } = useHealthRecord(id);
  const arianeApi = useArianeApi();
  const [prosAndTeams, setProsAndTeams] = useState<(Professional | Team)[] | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (healthRecord && healthRecord.notes.length > 0) {
        const proAndTeamIds = healthRecord.notes.flatMap(
          (note) => note.teamId ? [note.professionalId, note.teamId] : [note.professionalId]
        )

        let result: (Professional | Team)[] = [];

        try {
          result = await arianeApi.professionals.searchByIds([...new Set(proAndTeamIds)]);
        } catch (error) {
          result = [];
        }

        setProsAndTeams(result);
      }
    })();
  }, [arianeApi.professionals, healthRecord]);
  
  if (!healthRecord || healthRecord.notes.length == 0 || !prosAndTeams) { return null; }

  return (
    <>
      {healthRecord.notes.map((note, i) => {
        const pro = prosAndTeams.find((p) => p.id == note.professionalId);
        const team = prosAndTeams.find((t) => t.id == note.teamId);

        return (
          <Box key={note.id}>
            <Grid container>
              <Grid item xs={12}>
                <AuthorLabel pro={pro} team={team} />
              </Grid>
              <Grid item xs={12}>
                <Typography sx={{ wordWrap: "break-word" }}>{note.content}</Typography>
              </Grid>
              <Grid item xs={12}>
                <Timestamps note={note} />
              </Grid>
            </Grid>
            {i != healthRecord.notes.length -1 && <Divider sx={{ my: 1 }} />}
          </Box>
        )
      })}
    </>
  )
}

function AuthorLabel({ pro, team }: { pro: Professional | Team | undefined, team: Professional | Team | undefined }) {
  const { t } = useI18n();
  
  const label = useCallback(
    () => {
      const proLabel = pro ? `${(pro as Professional).first_name} ${(pro as Professional).last_name}` : t("notes.unknownAuthor")

      return team ? (team as Team).name : proLabel;
    }, [team, pro, t]
  )

  return (
    <Typography fontWeight="500" color={theme.palette.neutral[600]}>
      {label()}
    </Typography>
  )
}

function Timestamps({ note }: { note: Note }) {
  const { t, locale } = useI18n();

  return (
    <>
      <Typography component="span" color={theme.palette.neutral[500]} fontSize="0.85rem">
        {t(`notes.addedAt`, {
          distance: formatDistanceToNow(locale, note.createdAt),
        })}
      </Typography>
      {note.updatedAt.toISOString() != note.createdAt.toISOString() && (
        <Typography component="span" color={theme.palette.neutral[500]} fontSize="0.85rem">
          {" - " + t(`notes.lastModified`, {
            distance: formatDistanceToNow(locale, note.updatedAt),
          })}
        </Typography>
      )}
    </>
  )
}