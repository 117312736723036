import { useContext } from "react";
import { I18nContext, I18nInterface } from "../providers/I18nProvider";

export function useI18n(): I18nInterface {
    const value = useContext(I18nContext);
    if (value === undefined || value === null) {
      throw new Error("Did you forget to wrap your component in I18nProvider?");
    }
    return value;
}
