import {
  Alert,
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Paper,
  Skeleton,
  Snackbar,
  Stack,
} from "@mui/material";
import { Account } from "@syadem/kairos-citizen-js";
import { useCallback, useEffect, useState } from "react";
import { User } from "../../domain/user";
import { StyledInput } from "../components/mui/StyledInput";
import { useI18n } from "../hooks/useI18n";
import { useKairosApi } from "../hooks/useKairosApi";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";
import { useAuthenticatedUser } from "../hooks";
import { useServiceBus } from "../hooks/useServiceBus";
import { UserEditTypeEnum } from "../../services/editUserService";

export function AccountManagement() {
  const currentUser = useAuthenticatedUser();

  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [successDisplayed, setSuccessDisplayed] = useState<boolean>(false);

  const [account, setAccount] = useState<Account>();
  const [isUpdatingAccount, setIsUpdatingAccount] = useState<boolean>(false);

  const { t } = useI18n();

  const apis = useKairosApi();

  // Load account on mount
  useEffect(() => {
    (async () => {
      if (currentUser && apis) {
        try {
          const { account } = await apis.accountApi.showCurrentAccount();
          setAccount(account);
        } catch (e) {
          setErrorDisplayed(true);
        }
      }
    })();
  }, [apis, currentUser]);

  const handleEmailNotificationOptInChange = useCallback(
    (value: boolean) => {
      (async () => {
        if (apis && currentUser) {
          try {
            setIsUpdatingAccount(true);
            const { account } = await apis.accountApi.updateCurrentAccount({
              account: {
                emailNotificationOptin: value,
              },
            });
            setAccount(account);
            setSuccessDisplayed(true);
          } catch (e) {
            setErrorDisplayed(true);
          } finally {
            setIsUpdatingAccount(false);
          }
        }
      })();
    },
    [apis, currentUser],
  );

  return (
    <PageLayout title={t("informations.title")} data-testid="account-management-page">
      <AccountForm
        currentUser={currentUser}
        currentAccount={account}
        isUpdatingAccount={isUpdatingAccount}
        onEmailNotificationOptInChange={handleEmailNotificationOptInChange}
      />
      <Snackbar
        open={errorDisplayed}
        autoHideDuration={6000}
        onClose={() => setErrorDisplayed(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="error">{t("common.alerts.alert_classic")}</Alert>
      </Snackbar>
      <Snackbar
        open={successDisplayed}
        autoHideDuration={6000}
        onClose={() => setSuccessDisplayed(false)}
        anchorOrigin={{ vertical: "top", horizontal: "center" }}
      >
        <Alert severity="success">{t("common.alerts.alert_profile")}</Alert>
      </Snackbar>
    </PageLayout>
  );
}

function AccountForm({
  currentUser,
  currentAccount,
  isUpdatingAccount,
  onEmailNotificationOptInChange,
}: {
  currentUser?: User;
  currentAccount?: Account;
  isUpdatingAccount: boolean;
  onEmailNotificationOptInChange: (value: boolean) => void;
}) {
  const { t } = useI18n();
  const serviceBus = useServiceBus();

  const updateUserAttribute = useCallback(
    async (editType: UserEditTypeEnum) => {
      if (currentUser) {
        await serviceBus.dispatch({
          type: "editUser",
          editType,
        });
      }
    },
    [serviceBus, currentUser]
  );

  return (
    <>
      <Paper
        sx={{
          maxWidth: "sm",
          marginX: "auto",
          marginBottom: "16px",
          overflow: "hidden",
          border: `solid 1px ${theme.palette.neutral[200]}`,
        }}
        elevation={0}
      >
        <Stack spacing={2} sx={{ paddingX: "32px", paddingY: "16px" }}>
          {currentUser ? (
            <>
              <Stack direction="row" spacing={2}>
                <StyledInput name="email" fullWidth readOnly value={currentUser.email} label={t("account.email")} />
                <Box sx={{ display: "flex", alignItems: "center", pt: 3 }}>
                  <Button onClick={() => updateUserAttribute(UserEditTypeEnum.email)} variant="contained" disableElevation>
                    {t("common.cta.edit")}
                  </Button>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <StyledInput name="password" label={t("account.password")} fullWidth value="********" readOnly />
                <Box sx={{ display: "flex", alignItems: "center", pt: 3 }}>
                  <Button onClick={() => updateUserAttribute(UserEditTypeEnum.password)} variant="contained" disableElevation>
                    {t("common.cta.edit")}
                  </Button>
                </Box>
              </Stack>
              <Stack direction="row" spacing={2}>
                <StyledInput
                  name="phone"
                  label={t("account.mobile")}
                  fullWidth
                  value={currentUser.mobileNumber}
                  readOnly
                />
                <Box sx={{ display: "flex", alignItems: "center", pt: 3 }}>
                  <Button onClick={() => updateUserAttribute(UserEditTypeEnum.phoneNumber)} variant="contained" disableElevation>
                    {t("common.cta.edit")}
                  </Button>
                </Box>
              </Stack>
              <Alert severity="info">{t("account.infos")}</Alert>
            </>
          ) : (
            <Skeleton variant="text" />
          )}
        </Stack>
      </Paper>

      <Paper
        sx={{
          maxWidth: "sm",
          marginX: "auto",
          overflow: "hidden",
          border: `solid 1px ${theme.palette.neutral[200]}`,
        }}
        elevation={0}
      >
        <Stack spacing={2} sx={{ paddingX: "32px", paddingY: "16px" }}>
          <Stack direction="row" alignItems="center">
            <FormGroup>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={currentAccount?.emailNotificationOptin ?? false}
                    disabled={currentAccount === undefined || isUpdatingAccount}
                    onChange={(e, value) => {
                      e.preventDefault();
                      onEmailNotificationOptInChange(value);
                    }}
                  />
                }
                label={t("account.vaccination_reminders")}
              />
            </FormGroup>
          </Stack>
          <Alert severity="info">{t("account.vaccination_reminders_infos")}</Alert>
        </Stack>
      </Paper>
    </>
  );
}
