import { Professional, Team } from "@syadem/ariane-js";
import { VaccinationAct } from "@syadem/kairos-citizen-js";
import { I18nInterface } from "../ui/providers/I18nProvider";

export function certificationDescription(
  certificator: Professional | Team | undefined,
  vaccinationAct: VaccinationAct,
  t: I18nInterface["t"],
  locale: string
): string {
  let certificatorLabel = "";
  const dateLabel = vaccinationAct.certifiedOn?.toLocaleDateString(locale, { timeZone: "UTC" });

  if (vaccinationAct.certificator?.type === "professional") {
    if (certificator === undefined) {
      certificatorLabel = t("vaccines.aProfessional");
    } else {
      const professional = certificator as Professional;
      certificatorLabel = `${professional.first_name} ${professional.last_name}`;
    }
  } else if (vaccinationAct.certificator?.type === "team") {
    if (certificator === undefined) {
      certificatorLabel = t("vaccines.aTeam");
    } else {
      const team = certificator as Team;
      certificatorLabel = t("vaccines.aTeamMember", { teamInfo: `"${team.name}"` });
    }
  } else {
    return "";
  }

  if (vaccinationAct.certificationMethod === "execution") {
    return t("vaccines.validatedUponExecutionBy", { certificator: certificatorLabel, date: dateLabel });
  } else if (vaccinationAct.certificationMethod === "proof") {
    return t("vaccines.validatedUponProofBy", { certificator: certificatorLabel, date: dateLabel });
  } else {
    return "";
  }
}
