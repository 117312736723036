import { KairosCertApiInterface } from "../network/apis/kairosCertApi";

interface Payload {
  healthRecordId: string;
  extended?: boolean;
}

export class GetVaccinationCertificateQuery {
  constructor(private readonly kairosCertApi: KairosCertApiInterface) {}

  async call({ healthRecordId, extended = false }: Payload) {
    return await this.kairosCertApi.getVaccinationCertificate(healthRecordId, extended);
  }
}
