import Box from "@mui/material/Box";
import ReactCountryFlag from "react-country-flag";

export function FlagIcon({ locale }: { locale: string }) {
  return (
    <Box sx={{ fontSize: "12px" }}>
      <ReactCountryFlag
        countryCode={locale == "en-gb" ? "GB" : locale.toUpperCase()}
        svg
        style={{
          height: "1.3em",
          marginBottom: "3px",
          marginRight: "12px",
          borderRadius: "5px",
        }}
      />
      {locale.toUpperCase()}
    </Box>
  );
}
