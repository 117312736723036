import { Professional, Team } from "@syadem/ariane-js";
import { Daphne } from "@syadem/daphne-js";
import { HealthRecord, VaccinationAct } from "@syadem/kairos-citizen-js";
import { VaccinationActListItem } from "./VaccinationActListItem";
import { retrieveCertificator } from "../../utils/vaccinationActs";

function VaccinationActsByList({
  certificators,
  sortedVaccinationActs,
  healthRecord,
  daphne,
  refreshVaccinations,
  showActions = true,
}: {
  certificators: (Professional | Team)[];
  sortedVaccinationActs: VaccinationAct[];
  healthRecord: HealthRecord;
  daphne: Daphne;
  refreshVaccinations?: () => void;
  showActions?: boolean;
}) {
  return sortedVaccinationActs.map((vaccinationAct) => (
    <VaccinationActListItem
      vaccinationAct={vaccinationAct}
      healthRecord={healthRecord}
      showActions={showActions}
      refreshCallback={refreshVaccinations}
      key={vaccinationAct.id}
      daphne={daphne}
      certificator={retrieveCertificator(vaccinationAct, certificators)}
    />
  ));
}

export default VaccinationActsByList;
