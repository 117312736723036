import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import "../src/assets/index.css";
import { App } from "./App";

if (import.meta.env.PROD) {
  // Note that release and environment values configured here have to match sent
  // values uploaded to Sentry during build (see vite.config.ts)
  Sentry.init({
    dsn: "https://add11e828a5b4d3bb07027ddcbfc78f8@o477337.ingest.sentry.io/6493059",
    integrations: [new BrowserTracing()],
    release: import.meta.env.VITE_APP_CI_COMMIT_SHA,
    environment: import.meta.env.VITE_APP_CI_COMMIT_BRANCH,
    ignoreErrors: ["Non-Error promise rejection captured"],

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 0.1,
  });
}

const app = new App({
  oidcUrl: import.meta.env.VITE_AUTH_URL,
  kairosCitizenApiBasePath: import.meta.env.VITE_BACKEND_URL,
  kairosCertApiBasePath: import.meta.env.VITE_CERT_URL,
  sadApiBasePath: import.meta.env.VITE_SAD_URL,
  arianeApiBasePath: import.meta.env.VITE_ARIANE_URL,
  nuvaUrl: import.meta.env.VITE_NUVA_URL
});

app.start();