import { Configuration } from "@syadem/kairos-citizen-js";

export interface KairosCertApiInterface {
  getVaccinationCertificate(healthRecordId: string, extended: boolean): Promise<Blob>;
}

export class KairosCertApi implements KairosCertApiInterface {
  constructor(
    public readonly configuration: Configuration,
    public readonly kairosCertBasePath: string
  ) {}

  async getVaccinationCertificate(healthRecordId: string, extended: boolean): Promise<Blob> {
    if (this.configuration.accessToken === undefined) throw new Error("Access token undefined");

    const token = await this.configuration.accessToken();
    const path = `/private/citizen/health_records/${healthRecordId}`;
    const url = extended ? `${this.kairosCertBasePath}${path}?extended=true` : `${this.kairosCertBasePath}${path}`;
    const locale = localStorage.getItem("citizenLocale");
    const citizenLocale = JSON.parse(locale as string) === "en-gb" ? "en" : "fr";
    const response = await fetch(url, { headers: { Token: token, "Accept-Language": citizenLocale } });

    const blob = await response.blob();

    return blob;
  }
}
