import { useState, useEffect } from "react";
import { useDaphne } from "./useDaphne";
import { Disease, Vaccine } from "@syadem/daphne-js";

export function useDiseasesByVaccine(vaccine: Vaccine | undefined): {
  diseases?: Disease[];
} {
  const [diseases, setDiseases] = useState<Disease[] | undefined>(undefined);
  const daphne = useDaphne();
  useEffect(() => {
    if(daphne && vaccine) {
      setDiseases(daphne.queries.diseasesByVaccine(vaccine));
    }
  }, [daphne, vaccine]);
  return { diseases };
}
