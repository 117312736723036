import AddIcon from "@mui/icons-material/Add";
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import ShareIcon from "@mui/icons-material/Share";
import { Alert, Box, Button, LinearProgress, Link } from "@mui/material";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import { HealthRecord } from "@syadem/kairos-citizen-js";
import { useEffect, useState } from "react";
import { Link as RouterLink } from "react-router-dom";
import { EmptyListPlaceholder } from "../components/shared/EmptyListPlaceholder";
import HealthRecordListItemContent from "../components/shared/HealthRecordListItemContent";
import { useAllHealthRecords } from "../hooks/useAllHealthRecords";
import { useDiagnostics } from "../hooks/useDiagnostics";
import { useI18n } from "../hooks/useI18n";
import { PageLayout } from "../layout/PageLayout";
import { theme } from "../layout/Theme";

const NoHealthRecords = () => {
  const { t } = useI18n();
  return (
    <EmptyListPlaceholder
      title={t("health_records.none")}
      icon={<AssignmentIndIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
      button={
        <Button
          size="large"
          component={RouterLink}
          to="new"
          variant="contained"
          startIcon={<AddIcon />}
          disableElevation
        >
          {t("health_records.add_hr")}
        </Button>
      }
    >
      {t("health_records.share_msg_2")}
    </EmptyListPlaceholder>
  );
};

export default function Homepage() {
  const { t } = useI18n();
  const { isLoading, healthRecords, error, fetchHealthRecords } = useAllHealthRecords();

  const [filteredHealthRecords, setFilteredHealthRecords] = useState<HealthRecord[] | undefined>(healthRecords);
  useEffect(() => {
    if (!isLoading && healthRecords) {
      setFilteredHealthRecords(healthRecords);
    }
  }, [healthRecords, isLoading]);

  return (
    <PageLayout title={t("health_records.title")}>
      <Paper sx={{ overflow: "hidden", height: "100%", backgroundColor: "transparent" }} elevation={0}>
        {(error || (!isLoading && !healthRecords)) && (
          <Typography sx={{ my: 4 }} align="center" component="div">
            <Alert severity="error" data-testid="alert">
              {t("common.alerts.alert_notification")}
            </Alert>
          </Typography>
        )}

        {isLoading && <LinearProgress variant="query" />}

        {healthRecords && healthRecords.length > 0 && (
          <Box sx={{ height: "100%" }}>
            {filteredHealthRecords && filteredHealthRecords.length > 0 ? (
              <HealthRecordsList healthRecords={filteredHealthRecords} refreshCallback={fetchHealthRecords} />
            ) : (
              <NoHealthRecords />
            )}
          </Box>
        )}

        {!isLoading && healthRecords && healthRecords.length === 0 && <NoHealthRecords />}
      </Paper>
    </PageLayout>
  );
}

interface HealthRecordsListProps {
  healthRecords: HealthRecord[];
  refreshCallback: () => void;
}

function HealthRecordsList(props: HealthRecordsListProps) {
  const { healthRecords } = props;
  const { t } = useI18n();
  const allDiagnostics = useDiagnostics(healthRecords);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          width: "90%",
          minWidth: "222px",
          gap: "10px",
          '@media screen and (max-width: 1000px)': {
            alignItems: "center",
          },
          '@media screen and (max-width: 660px)': {
            alignItems: "center",
          },
          '@media screen and (max-width: 450px)': {
            flexWrap: "nowrap",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          },
        }}
      >
        {healthRecords.map((healthRecord) => (
          <Box
            className={`linkHealthRecord`}
            style={{ boxShadow: '3px 3px 10px 3px rgba(0, 0, 0, 0.05)' }}
            sx={{
              display: "flex",
              flexDirection: "column",
              width: "24%",
              minHeight: "280px",
              borderRadius: 1,
              backgroundColor: "background.paper",
              justifyContent: "space-between",
              mt: 1,
              mb: 1,
              marginLeft: "1px",
              '@media screen and (max-width: 1000px)': {
                width: "32%",
                minHeight: "280px",
              },
              '@media screen and (max-width: 660px)': {
                width: "48%",
                minHeight: "280px",
              },
              '@media screen and (max-width: 450px)': {
                width: "90%",
                minHeight: "280px",
                mr: 0,
                ml: 1,
                mt: 1,
              },
              "&:hover": {
                outline: `solid 1px ${theme.palette.primary[500]}`,
                cursor: "pointer",
              },
            }}
            key={healthRecord.id}
          >
            <Link
              href={`/health-records/${healthRecord.id}`}
              sx={{
                minHeight: "220px",
                display: "flex",
                width: "100%",
                flexDirection: "column",
                justifyContent: "space-between",
                textDecoration: "none",
              }}
              data-testid="health-record"
            >
              <HealthRecordListItemContent
                healthRecord={healthRecord}
                testId="healthRecordContent"
                diagnosticIsLoading={allDiagnostics.isLoading}
                diagnostic={
                  allDiagnostics?.diagnostics?.find((diag) => diag.healthRecordId === healthRecord.id)?.diagnostic
                }
              />
            </Link>

            <Box
              sx={{
                display: "flex",
                height: "30px",
                width: "100%",
                flexDirection: "column",
                alignSelf: "flex-end",
                justifyContent: "flex-end",
                marginBottom: "0",
                marginLeft: "1.3rem",
                paddingLeft: "8px",
                paddingBottom: "2px",
              }}
            >
              {(healthRecord.sharedWithCitizens?.length > 1 ||
                healthRecord.sharedWithPro?.length > 0 ||
                healthRecord.sharedWithTeams?.length > 0) ? (
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <ShareIcon
                    fontSize="small"
                    sx={{
                      mr: 1,
                      height: "14px",
                      width: "14px",
                      color: theme.palette.neutral[500]
                    }}
                  />
                  <Typography variant="body2" sx={{ color: theme.palette.neutral[500] }}>
                    {t("share.shared")}
                  </Typography>
                </Box>
              ) : (
                <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                  <Link
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      textDecoration: "none",
                    }}
                    href={`/health-records/${healthRecord.id}/shares`}
                  >
                    <ShareIcon
                      fontSize="small"
                      sx={{
                        mr: 1,
                        height: "14px",
                        width: "14px",
                        color: theme.palette.primary[500]
                      }}
                    />
                    <Typography variant="body2" sx={{ color: theme.palette.primary[500] }}>
                      {t("health_records.share")}
                    </Typography>
                  </Link>
                </Box>
              )}
            </Box>
          </Box>
        ))}

          <Button
              component={RouterLink}
              to={"new"}
              startIcon={
                  <AddCircleIcon
                    fontSize="small"
                    sx={{
                      color: "theme.palette.primary[500]",
                      width: { xs: 24, md: 32 },
                      height: { xs: 24, md: 32 },
                    }}
                  />
              }
              sx={{
                display: "flex",
                flexDirection: "column",
                width: "24%",
                borderRadius: 1,
                justifyContent: "center",
                gap: "1.5rem",
                border: `solid 1px ${theme.palette.primary[500]}`,
                marginTop: "7px",
                minHeight: "280px",
                marginBottom: "0.5rem",
                '@media screen and (max-width: 1000px)': {
                  width: "32%",
                  marginTop: "6px",
                  minHeight: "280px",
                },
                '@media screen and (max-width: 660px)': {
                  width: "48%",
                  marginTop: "6px",
                  minHeight: "280px",
                },
                '@media screen and (max-width: 450px)': {
                  width: "90%",
                  minHeight: "280px",
                  mr: 0,
                  ml: 1,
                },
                "&:hover": {
                  cursor: "pointer",
                  background: "none",
                },
              }}
              disableElevation
            >
              <Typography color="primary" fontWeight="500" sx={{ fontSize: { xs: 14, md: 16 }, alignSelf: "center" }}>
                {t("patients.add")}
              </Typography>
            </Button>
      </Box>
    </Box>
  );
}
