import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import LogoutIcon from "@mui/icons-material/Logout";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import {
  Avatar,
  Button,
  Chip,
  Divider,
  IconButton,
  Link,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Skeleton,
  Tooltip,
  useMediaQuery,
} from "@mui/material";
import Box from "@mui/material/Box";
import Drawer, { DrawerProps } from "@mui/material/Drawer";
import { MouseEventHandler, ReactElement, useCallback } from "react";
import { Link as RouterLink, useMatch, useResolvedPath } from "react-router-dom";
import { LanguageSelector } from "../components/LanguageSelector";
import { ListItemLink } from "../components/shared/ListItemLink";
import { useAllHealthRecords } from "../hooks/useAllHealthRecords";
import { useI18n } from "../hooks/useI18n";
import { theme } from "./Theme";
import { useAuthenticatedUser } from "../hooks";
import { useServiceBus } from "../hooks/useServiceBus";
import logo from "../../assets/large_logo.png";

export default function Navigator(props: DrawerProps) {
  const { ...other } = props;
  const serviceBus = useServiceBus();
  const { t } = useI18n();
  const breakpointSm = useMediaQuery(theme.breakpoints.down("sm"));
  const currentUser = useAuthenticatedUser();

  const handleSignout = useCallback(() => {
    serviceBus.dispatch({ type: "signOut" });
  }, [serviceBus]);

  return (
    <Drawer variant="permanent" {...other} sx={{ ".MuiDrawer-paper": { border: "none" } }}>
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        sx={{ borderRight: `solid 1px ${theme.palette.neutral[200]}` }}
      >
        <Box display="flex" flex="1 1 auto" overflow="auto">
          <Box sx={{ height: "100%", pl: 2, pr: 2 }}>
            <HeaderMenu />
            <AccountMenu />
            <Box sx={{ mt: 3 }}>
              <MenuListItem to="/health-records" text={t("health_records.title")} icon={<AssignmentIndIcon />} />
            </Box>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          {breakpointSm && (
            <Box sx={{ display: "flex", flexDirection: "column", alignItems: "center", width: "100%" }}>
              <LanguageSelector width="100%" />
              <Divider sx={{ width: "100%", my: 2 }} />
              <div>
                <a
                  href="https://support-cvn.mesvaccins.net"
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    color: theme.palette.neutral[600],
                  }}
                >
                  <HelpCenterOutlinedIcon sx={{ mr: 2 }} />
                  {t("common.helpCenter")}
                </a>
                <a
                  href={`https://support-cvn.mesvaccins.net/hc/fr/requests/new?tf_anonymous_requester_email=${
                    currentUser?.email
                  }${currentUser?.mobileNumber ? `&tf_13137831594129=${currentUser?.mobileNumber}` : ""}`}
                  target="_blank"
                  rel="noreferrer"
                  style={{
                    textDecoration: "none",
                    display: "inline-flex",
                    alignItems: "center",
                    color: theme.palette.neutral[600],
                  }}
                >
                  <MailOutlineIcon sx={{ mr: 2 }} />
                  {t("common.createHelpTicket")}
                </a>
              </div>
            </Box>
          )}
          <Button
            fullWidth
            variant="outlined"
            onClick={handleSignout}
            sx={{ padding: "8px", borderRadius: "5px", mt: { xs: 3, md: 0 } }}
            startIcon={<LogoutIcon />}
            color="error"
          >
            <div style={{ margin: "3.25px 0" }}>{t("common.cta.logout")}</div>
          </Button>
        </Box>
      </Box>
    </Drawer>
  );
}

const HeaderMenu = () => {
  return (
    <Box>
      <Link to="/" underline="none" component={RouterLink}>
        <Box sx={{ pr: 1, margin: 2.4 }}>
          <img src={logo} width="100%" />
        </Box>
      </Link>
    </Box>
  );
};

const AccountMenu = () => {
  const currentUser = useAuthenticatedUser();
  const { t } = useI18n();

  return (
    <>
      <Divider sx={{ borderColor: theme.palette.neutral[200] }} />
      <Box
        sx={{
          py: 1,
          my: 1,
          display: "flex",
          alignItems: "center",
          color: theme.palette.neutral[600],
        }}
      >
        <AccountCircleIcon />
        {currentUser ? (
          <Box
            sx={{
              pl: 2,
              fontSize: "14px",
              flex: 1,
            }}
          >
            {currentUser.email}
          </Box>
        ) : (
          <Box sx={{ px: 2, width: "100%" }}>
            <Skeleton variant="text" />
          </Box>
        )}
        <Tooltip title={t("common.interface.informations")} placement="right" arrow>
          <Avatar sx={{ backgroundColor: theme.palette.primary[100], mr: 1 }}>
            <IconButton
              component={RouterLink}
              to="/account"
              sx={{
                color: theme.palette.primary.main,
                height: "100%",
                width: "100%",
                borderRadius: "5px",
              }}
            >
              <ManageAccountsIcon />
            </IconButton>
          </Avatar>
        </Tooltip>
      </Box>
      <Divider sx={{ borderColor: theme.palette.neutral[200], mb: 3 }} />
    </>
  );
};

interface MenuListItemProps {
  to: string;
  text: string;
  icon?: ReactElement;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
  disabled?: boolean;
}

function MenuListItem({ to, text, icon, onClick, disabled = false }: MenuListItemProps) {
  const resolved = useResolvedPath(to);
  const match = useMatch({ path: resolved.pathname, end: false });
  const selected = match !== null;
  const { isLoading, healthRecords } = useAllHealthRecords();
  return (
    <ListItemLink disablePadding disabled={disabled} to={to} onClick={onClick} sx={{ my: 0.5, mx: 0 }}>
      <ListItemButton
        disabled={disabled}
        selected={selected}
        sx={{
          color: theme.palette.neutral[600],
          borderRadius: 1,
        }}
      >
        {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
        <ListItemText>{text}</ListItemText>
        {healthRecords && !isLoading && <Chip label={healthRecords.length} size="small" />}
      </ListItemButton>
    </ListItemLink>
  );
}
