import { HealthRecord } from "@syadem/kairos-citizen-js";
import { FilteredSection } from "@syadem/sad-js";
import { useEffect, useState } from "react";
import { dayjs } from "../../utils/dayjs";
import { useSad } from "./useSadApi";

export function useProfilQuestionnaire(healthRecord: HealthRecord | undefined): {
  isLoading: boolean;
  questionnaireSections?: FilteredSection[];
  error?: Response | Error;
} {
  const api = useSad();
  const [questionnaireSections, setQuestionnaireSections] = useState<FilteredSection[] | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (api) {
        try {
          if (healthRecord) {
            const questionnaireSections: FilteredSection[] = await api.profilQuestionnaire({
              patient: {
                birthdate: dayjs.utc(healthRecord.birthDate).format("YYYY-MM-DD"),
                gender: healthRecord.gender.toLowerCase(),
                // area_of_residency: {
                //   zipcode: values.zipcode,
                // },
              },
            });
            setQuestionnaireSections(questionnaireSections);
          }
        } catch (error) {
          if (error instanceof Response || error instanceof Error) {
            setError(error);
          } else {
            throw error;
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [api, healthRecord]);

  return { isLoading, questionnaireSections, error };
}
