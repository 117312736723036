import { Typography } from "@mui/material";
import { City } from "@syadem/ariane-js";
import debounce from "awesome-debounce-promise";
import { isEqual } from "lodash-es";
import { useEffect, useMemo, useState } from "react";
import { TranslateFunction } from "../../utils/formUtils";
import { useArianeApi } from "../hooks/useArianeApi";
import { StyledAutocomplete } from "./mui/StyledAutoComplete";

export function CitiesAutocomplete({
  label,
  placeholder,
  error,
  errorMessage,
  touched,
  translateErrors,
  required,
  readOnly,
  cityCode,
  onChange,
}: {
  label: string;
  placeholder?: string;
  error?: boolean;
  errorMessage?: string;
  touched?: boolean;
  translateErrors?: TranslateFunction;
  required?: boolean;
  readOnly?: boolean;
  cityCode?: string;
  onChange: (city?: City) => void;
}) {
  const arianeApi = useArianeApi();
  const [cities, setCities] = useState<City[]>([]);
  const [cityInput, setCityInput] = useState<string | undefined>(undefined);
  const [isLoadingCities, setIsLoadingCities] = useState<boolean>(false);
  const [openCities, setOpenCities] = useState(false);
  const [defaultCity, setDefaultCity] = useState<City | undefined>(undefined);

  const searchCitiesDebounced = useMemo(
    () =>
      debounce(async (cityInput) => {
        setIsLoadingCities(true);
        const result =
          !isNaN(parseFloat(cityInput)) && isFinite(Number(cityInput))
            ? await arianeApi.cities.searchByZipCode(cityInput)
            : await arianeApi.cities.search(cityInput);
        setCities(result);
        setIsLoadingCities(false);
      }, 300),
    [arianeApi?.cities, setIsLoadingCities, setCities],
  );

  // Pull cities on city input change
  useEffect(() => {
    if (cityInput && arianeApi?.cities) {
      searchCitiesDebounced(cityInput);
    } else {
      setCities([]);
    }
  }, [arianeApi, cityInput, searchCitiesDebounced]);

  useEffect(() => {
    if (cityCode && arianeApi?.cities) {
      //Get default city
      arianeApi.cities.getByCode(cityCode).then((data) => {
        setDefaultCity(data);
      });
    }
  }, [arianeApi?.cities, cityCode]);

  return (
    <StyledAutocomplete
      open={openCities}
      onOpen={() => {
        // only open when in focus and cityInput is not empty
        if (cityInput) {
          setOpenCities(true);
        }
      }}
      label={label}
      placeholder={placeholder}
      error={error}
      errorMessage={errorMessage}
      touched={touched}
      translateErrors={translateErrors}
      readOnly={readOnly}
      onClose={() => setOpenCities(false)}
      fullWidth
      options={cities}
      filterOptions={(x) => x}
      loading={isLoadingCities}
      testId="cities"
      required={required}
      getOptionLabel={(city) => (city.department ? `${city.name} (${city.department})` : city.name)}
      renderOption={(props, city) => (
        <li data-testid="cityOption" {...props}>
          <Typography fontSize="0.8rem">{city.department ? `${city.name} (${city.department})` : city.name}</Typography>
        </li>
      )}
      value={defaultCity || null}
      onInputChange={(_event, newCityInput, reason) => {
        if (reason === "input") {
          setCityInput(newCityInput);

          if (!newCityInput) {
            setOpenCities(false);
          }
        }
      }}
      onChange={(_event, newCity) => {
        onChange(newCity ?? undefined);
        if (!newCity) {
          setCityInput(undefined);
          setOpenCities(false);
        }
      }}
      isOptionEqualToValue={isEqual}
    />
  );
}
