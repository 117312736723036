import ClearIcon from "@mui/icons-material/Clear";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { HealthRecord, Team } from "@syadem/kairos-citizen-js";
import { capitalize } from "lodash-es";
import { useI18n } from "../hooks/useI18n";
import { useKairosApi } from "../hooks/useKairosApi";
import { theme } from "../layout/Theme";
import IconMedicalTeamRegular from "./icons/IconMedicalTeamRegular";
import { ListItemDeleteButton } from "./shared/ListItemDeleteButton";

interface TeamShareListItemProps {
  healthRecord: HealthRecord;
  team: Team;
  refetch: () => void;
}

export function TeamShareListItem(props: TeamShareListItemProps) {
  const { healthRecord, team, refetch } = props;
  const { t } = useI18n();
  const api = useKairosApi();

  return (
    <ListItem
      sx={{
        px: 3,
        py: 2,
        mb: 1,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
      }}
      secondaryAction={
        api && (
          <ListItemDeleteButton
            deleteRecord={() => api.healthRecordApi.unshare(healthRecord.id, { team: { id: team.id } })}
            refreshCallback={refetch}
            title={t("health_records.revoke")}
            modalTitle={t("health_records.revoke_access")}
            modalContent={
              <p>
                {t("health_records.revoke_access_start")}
                <Typography component="span" fontWeight="bold">
                  {team.fullName}
                </Typography>
                {t("health_records.revoke_access_end_team")}
              </p>
            }
            icon={<ClearIcon fontSize="small" color="error" />}
            variant="outlined"
            buttonWidth="110px"
          />
        )
      }
    >
      <ListItemAvatar>
        <Avatar sx={{ background: theme.palette.primary[100] }}>
          <IconMedicalTeamRegular color={theme.palette.primary[500]} style={{ height: "20px" }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={<Typography>{capitalize(team.fullName)}</Typography>} secondary={t("users.team")} />
    </ListItem>
  );
}
