import { VaccinationAct } from "@syadem/kairos-citizen-js";
import { useCallback, useEffect, useState } from "react";
import { useKairosApi } from "./useKairosApi";

export function useHealthRecordVaccinations(id: string): {
  isLoading: boolean;
  vaccinationActs?: VaccinationAct[];
  error?: Response | Error;
  refreshVaccinations: () => void;
} {
  const api = useKairosApi();

  const [vaccinationActs, setVaccinationActs] = useState<VaccinationAct[] | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [refreshCounter, setRefreshCounter] = useState(0);

  const refreshVaccinations = useCallback(() => {
    setRefreshCounter((prev) => prev + 1);
  }, []);

  useEffect(() => {
    (async () => {
      if (api) {
        try {
          const { vaccinationActs } = await api.vaccinationActApi.indexVaccinationActsForHealthRecord(id);
          setVaccinationActs(vaccinationActs);
        } catch (error) {
          if (error instanceof Response || error instanceof Error) {
            setError(error);
          } else {
            throw error;
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [api, id, refreshCounter]);

  return { isLoading, vaccinationActs, error, refreshVaccinations };
}
