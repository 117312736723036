import { HealthRecord } from "@syadem/kairos-citizen-js";
import { Diagnostic } from "@syadem/sad-js";
import { useEffect, useState } from "react";
import { buildPatientFromHealthRecord } from "../../domain/patient";
import { useSad } from "./useSadApi";

export function useDiagnostics(healthRecords?: HealthRecord[]): {
  isLoading: boolean;
  diagnostics?: { healthRecordId: string; diagnostic: Diagnostic }[];
  error?: Response | Error;
} {
  const api = useSad();
  const [diagnostics, setDiagnostics] = useState<{ healthRecordId: string; diagnostic: Diagnostic }[] | undefined>(
    undefined
  );
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    (async () => {
      if (api) {
        try {
          if (healthRecords) {
            const promises = healthRecords?.map(async (healthRecord) => {
              const response = await api.diagnosticForPatient(buildPatientFromHealthRecord(healthRecord));
              return { healthRecordId: healthRecord.id, diagnostic: response };
            });

            Promise.all(promises || []).then((results) => {
              setDiagnostics(results);
              setIsLoading(false);
            });
          }
        } catch (error) {
          if (error instanceof Response || error instanceof Error) {
            setError(error);
          } else {
            throw error;
          }
          setIsLoading(false);
        }
      }
    })();
  }, [api, healthRecords]);

  return { isLoading, diagnostics, error };
}
