import { useFormikContext } from "formik";
import Polyglot from "node-polyglot";
import { I18nKey, YupI18nKey } from "../ui/providers/I18nProvider";

export declare type ErrorI18nObject = {
  key: YupI18nKey;
  values?: {
    [key: string]: any;
  };
};
export declare type Error = string | ErrorI18nObject;
export declare type TranslateFunction = (error: ErrorI18nObject) => string;

export const createTranslateFn =
  (t: (phrase: I18nKey, options?: number | Polyglot.InterpolationOptions | undefined) => string): TranslateFunction =>
  ({ key, values }: ErrorI18nObject) => {
    return t(`yup.${key}`, values);
  };

export const FormDebug = () => {
  const { values, errors, touched, ...rest } = useFormikContext();

  return (
    <div style={{ marginTop: "20px" }}>
      <strong>Values</strong>
      <div>{JSON.stringify(values, null, 2)}</div>
      <br />
      <strong>Errors</strong>
      <div>{JSON.stringify(errors, null, 2)}</div>
      <br />
      <strong>Touched</strong>
      <div>{JSON.stringify(touched, null, 2)}</div>
      <br />
      <strong>Form State</strong>
      <div>{JSON.stringify(rest, null, 2)}</div>
    </div>
  );
};
