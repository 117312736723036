import { useEffect, useState } from "react";
import { getTranslatedFieldByLocale } from "../../utils/translatedFields";
import { useDaphne } from "./useDaphne";
import { useI18n } from "./useI18n";
import { VaccineWithDiseases, getDiseasesNames } from "./useVaccinesWithDiseases";

export function useVaccineWithDiseases(vaccineId: string): VaccineWithDiseases | undefined {
  const [vaccineWithDiseases, setVaccineWithDiseases] = useState<VaccineWithDiseases | undefined>(undefined);
  const daphne = useDaphne();
  const { locale } = useI18n();
  useEffect(() => {
    if (daphne && vaccineId) {
      const vaccine = daphne?.repositories.vaccines.find(vaccineId);
      if (vaccine) {
        const diseases = daphne.queries.diseasesByVaccine(vaccine);
        const diseasesName = getDiseasesNames(diseases, locale);
        setVaccineWithDiseases({
          vaccine,
          diseases,
          vaccineName: getTranslatedFieldByLocale(locale, vaccine.name),
          diseasesName,
          vaccineNameWithDiseases: `${getTranslatedFieldByLocale(locale, vaccine.name)}${
            diseasesName ? ` (${diseasesName})` : ""
          }`,
        });
      }
    }
  }, [daphne, vaccineId, locale]);
  return vaccineWithDiseases;
}
