import dayjsLib from "dayjs";
import "dayjs/locale/en-gb";
import "dayjs/locale/fr";
import customParseFormat from "dayjs/plugin/customParseFormat";
import localeData from "dayjs/plugin/localeData";
import localizedFormat from "dayjs/plugin/localizedFormat";
import relativeTime from "dayjs/plugin/relativeTime";
import utc from "dayjs/plugin/utc";

dayjsLib.extend(customParseFormat);
dayjsLib.extend(utc);
dayjsLib.extend(localizedFormat);
dayjsLib.extend(localeData);
dayjsLib.extend(relativeTime);
export const dayjs = dayjsLib;

export const formatDistanceToNow = (locale: string, date: Date) => {
  return dayjs(date)
    .locale(locale == "fr" ? "fr" : "en")
    .fromNow(true);
};

export const dayjsWithLocale = (date: string | Date, locale: string) => {
  const format = locale === "fr" ? "DD/MM/YYYY" : "MM/DD/YYYY";
  return dayjs(date).locale(locale).format(format);
};
