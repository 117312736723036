import { HealthRecord } from "@syadem/kairos-citizen-js";
import { useEffect, useState } from "react";
import { useKairosApi } from "./useKairosApi";

interface UseHealthRecords {
  isLoading: boolean;
  healthRecords?: HealthRecord[];
  error?: Response | Error;
  fetchHealthRecords: () => void;
}

export function useAllHealthRecords(): UseHealthRecords {
  const api = useKairosApi();

  const [healthRecords, setHealthRecords] = useState<HealthRecord[] | undefined>(
    undefined
  );
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const fetchHealthRecords = () => {
    (async () => {
      if (api) {
        try {
          const { healthRecords } = await api.healthRecordApi.indexHealthRecords();
          setHealthRecords(healthRecords);
        } catch (error) {
          if (error instanceof Response || error instanceof Error) {
            setError(error);
          } else {
            throw error;
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
  };

  useEffect(fetchHealthRecords, [api]);

  return { isLoading, healthRecords, error, fetchHealthRecords };
}
