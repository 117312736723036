import { useContext } from "react";
import { Queries } from "../../queries";
import { DependenciesContext } from "../providers/DependenciesProvider";

export function useQueries(): Queries {
  const dependencies = useContext(DependenciesContext);
  if (!dependencies) {
    throw new Error("Dependencies container is null, did you forget to pass it to the provider?");
  } else {
    return dependencies.queries;
  }
}
