import { createContext } from "react";
import { AppStore } from "../../store";
import { Apis } from "../../network/apis";
import { ServiceBus } from "../../services/serviceBus";
import { Queries } from "../../queries";
import { Daphne } from "@syadem/daphne-js";
import { SadvApi } from "@syadem/sad-js";
import { ApiClient as ArianeApi } from "@syadem/ariane-js";
import { CountryConfig } from "../../appConfig";

export interface Dependencies {
  store: AppStore;
  serviceBus: ServiceBus;
  queries: Queries;
  daphne: Daphne;
  sadApi: SadvApi;
  arianeApi: ArianeApi;
  apis: Apis;
  countryConfig: CountryConfig;
}

export const DependenciesContext = createContext<Dependencies | null>(null);

export function DependenciesProvider({
  children,
  dependencies,
}: {
  dependencies: Dependencies;
  children?: React.ReactNode;
}) {
  return <DependenciesContext.Provider value={dependencies}>{children}</DependenciesContext.Provider>;
}
