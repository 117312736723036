import { createStore } from "zustand/vanilla";
import { devtools } from "zustand/middleware";
import { User } from "./domain/user";
import { ActionResult } from "./actionResult";

type AuthState = { type: "signed-out" } | { type: "signed-in"; user: User } | { type: "error"; error: Error };

export interface AppState {
  authState: AuthState;
  actionResults: ActionResult<unknown>[];
}

export const initStore = () => {
  const devtoolsImpl = import.meta.env.MODE === "development" ? devtools : (((x) => x) as typeof devtools);

  return createStore<AppState>()(
    devtoolsImpl(
      () =>
        ({
          authState: { type: "signed-out" },
          actionResults: []
        }) as AppState
    )
  );
};

export type AppStore = ReturnType<typeof initStore>;