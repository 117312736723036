import { VaccinationAct } from "@syadem/kairos-citizen-js";
import { useEffect, useState } from "react";
import { useHealthRecordVaccinations } from "./useHealthRecordVaccinations";

export function useHealthRecordVaccination(healthRecordId: string, vaccinationActId: string): {
  isLoading: boolean;
  vaccinationAct?: VaccinationAct;
  error?: Response | Error;
} {
  const { isLoading, vaccinationActs, error } = useHealthRecordVaccinations(healthRecordId);
  const [vaccinationAct, setVaccinationAct] = useState<VaccinationAct | undefined>(undefined);
  useEffect(() => {
    vaccinationActs &&
    vaccinationActs.length > 0 &&
    setVaccinationAct(vaccinationActs.find(vaccinationAct => vaccinationAct.id === vaccinationActId));
  }, [vaccinationActs, vaccinationActId]);

  return { isLoading, vaccinationAct, error };
}
