import { HealthRecord } from "@syadem/kairos-citizen-js";
import { useCallback, useEffect, useState } from "react";
import { useKairosApi } from "./useKairosApi";

export function useHealthRecord(id: string): {
  isLoading: boolean;
  healthRecord?: HealthRecord;
  error?: Response | Error;
  refetch: () => void;
} {
  const api = useKairosApi();

  const [healthRecord, setHealthRecord] = useState<HealthRecord | undefined>(undefined);
  const [error, setError] = useState<Response | Error | undefined>(undefined);
  const [isLoading, setIsLoading] = useState(true);

  const refetch = useCallback(() => {
    (async () => {
      if (api) {
        try {
          const { healthRecord } = await api.healthRecordApi.showHealthRecord(id);
          setHealthRecord(healthRecord);
        } catch (error) {
          if (error instanceof Response || error instanceof Error) {
            setError(error);
          } else {
            throw error;
          }
        } finally {
          setIsLoading(false);
        }
      }
    })();
  }, [api, id]);

  useEffect(refetch, [refetch]);

  return { isLoading, healthRecord, error, refetch };
}
