import ClearIcon from "@mui/icons-material/Clear";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { HealthRecord, Pro, UnshareBodyAccountTypeEnum } from "@syadem/kairos-citizen-js";
import { capitalize } from "lodash-es";
import { useI18n } from "../hooks/useI18n";
import { useKairosApi } from "../hooks/useKairosApi";
import { theme } from "../layout/Theme";
import IconDoctor from "./icons/IconDoctor";
import { ListItemDeleteButton } from "./shared/ListItemDeleteButton";

interface ProShareListItemProps {
  healthRecord: HealthRecord;
  pro: Pro;
  refetch: () => void;
}

export function ProShareListItem(props: ProShareListItemProps) {
  const { healthRecord, pro, refetch } = props;
  const { t } = useI18n();
  const api = useKairosApi();

  const showPro = (pro: Pro) => {
    if (pro.firstName && pro.lastName) {
      return <Typography>{`${capitalize(pro.firstName)} ${capitalize(pro.lastName)}`}</Typography>;
    } else if (pro.email) {
      return <Typography>{pro.email}</Typography>;
    } else {
      <></>;
    }
  };

  return (
    <ListItem
      sx={{
        px: 3,
        py: 2,
        mb: 1,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
      }}
      secondaryAction={
        api && (
          <ListItemDeleteButton
            deleteRecord={() => {
              return api.healthRecordApi.unshare(healthRecord.id, {
                account: {
                  id: pro.id,
                  type: UnshareBodyAccountTypeEnum.Pro,
                },
              });
            }}
            refreshCallback={refetch}
            title={t("health_records.revoke")}
            modalTitle={t("health_records.revoke_access")}
            modalContent={
              <p>
                {t("health_records.revoke_access_start")}
                <Typography component="span" fontWeight="bold">{`${pro.firstName} ${pro.lastName}`}</Typography>
                {t("health_records.revoke_access_end_pro")}
              </p>
            }
            icon={<ClearIcon fontSize="small" color="error" />}
            variant="outlined"
            buttonWidth="110px"
          />
        )
      }
    >
      <ListItemAvatar>
        <Avatar sx={{ background: theme.palette.primary[100] }}>
          <IconDoctor color={theme.palette.primary[500]} style={{ height: "20px" }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={showPro(pro)} secondary={t("users.pro")} />
    </ListItem>
  );
}
