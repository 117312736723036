import { UserManager } from "oidc-client-ts";
import { AppStore } from "../store";
import { Service, ServicePayload } from "./service";
import { SignInService } from "./signInService";
import { SignOutService } from "./signOutService";
import { EditUserService } from "./editUserService";
import { AppRouter } from "../appConfig";

type ServiceCollection = {
  // We don't care about the return type of the service here
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: Service<ServicePayload, any>;
};

export interface Services extends ServiceCollection {
  signIn: SignInService;
  signOut: SignOutService;
  editUser: EditUserService;
}

export function initServices({
  store,
  oidcManager,
  router
}: {
  store: AppStore;
  oidcManager: UserManager;
  router: AppRouter;
}): Services {
  return {
    signIn: new SignInService(store, window, oidcManager, router),
    signOut: new SignOutService(window, oidcManager),
    editUser: new EditUserService(window, oidcManager)
  };
}
