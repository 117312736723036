import { Box, Container } from "@mui/material";
import { ReactNode } from "react";
import { Copyright } from "./Copyright";
import Header from "./Header";
import { theme } from "./Theme";

export function PageLayout({
  children,
  title,
  tabs,
}: {
  children: ReactNode;
  title?: ReactNode;
  tabs?: ReactNode;
}): JSX.Element {
  return (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        flexDirection: "column",
        backgroundColor: theme.palette.neutral[100],
        height: "100vh",
        width: "100vw",
      }}
    >
      <Box sx={{ backgroundColor: "background.paper", borderBottom: `solid 1px ${theme.palette.neutral[200]}` }}>
        <Container maxWidth="lg" sx={{ flex: 1, display: "flex", flexDirection: "column" }}>
          <Header tabs={tabs}>{title}</Header>
        </Container>
      </Box>
      <Box sx={{ overflow: "auto", height: "100%" }}>
        <Container
          maxWidth="lg"
          sx={{ flex: 1, display: "flex", flexDirection: "column", height: "100%", px: { xs: 1, sm: 0 } }}
        >
          <Box component="main" sx={{ flex: 1, py: { xs: 1, sm: 2, md: 3 }, px: { xs: 0, sm: 2, md: 5 } }}>
            {children}
          </Box>
        </Container>
      </Box>
      <Box component="footer" sx={{ p: 2 }}>
        <Copyright />
      </Box>
    </Box>
  );
}
