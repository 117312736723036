import HelpCenterOutlinedIcon from "@mui/icons-material/HelpCenterOutlined";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import QuestionMarkOutlinedIcon from "@mui/icons-material/QuestionMarkOutlined";
import { Avatar, Box, IconButton } from "@mui/material";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import React from "react";
import { useI18n } from "../hooks/useI18n";
import { theme } from "./Theme";
import { useAuthenticatedUser } from "../hooks";

function HelpCenterMenu() {
  const { t } = useI18n();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const currentUser = useAuthenticatedUser();

  return (
    <Box sx={{ mr: 2 }}>
      <IconButton
        id="helpCenterMenuButton"
        aria-controls={open ? "helpCenterMenu" : undefined}
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        <Avatar sx={{ background: theme.palette.primary.main, width: 24, height: 24 }}>
          <QuestionMarkOutlinedIcon sx={{ color: "white" }} fontSize="small" />
        </Avatar>
      </IconButton>
      <Menu
        id="helpCenterMenu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "helpCenterMenuButton",
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            overflow: "visible",
            filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
            mt: 1.5,
            "&:before": {
              content: '""',
              display: "block",
              position: "absolute",
              top: 0,
              right: 14,
              width: 10,
              height: 10,
              bgcolor: "background.paper",
              transform: "translateY(-50%) rotate(45deg)",
              zIndex: 0,
            },
          },
        }}
        transformOrigin={{ horizontal: "right", vertical: "top" }}
        anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
      >
        <MenuItem
          sx={{
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          <a
            href="https://support-cvn.mesvaccins.net"
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              display: "inline-flex",
              alignItems: "center",
              color: theme.palette.neutral[600],
            }}
          >
            <HelpCenterOutlinedIcon sx={{ mr: 2 }} />
            {t("common.helpCenter")}
          </a>
        </MenuItem>
        <MenuItem
          onClick={handleClose}
          sx={{
            color: theme.palette.neutral[600],
            "&:hover": {
              color: theme.palette.primary.main,
            },
          }}
        >
          <a
            href={`https://support-cvn.mesvaccins.net/hc/fr/requests/new?tf_anonymous_requester_email=${
              currentUser?.email
            }${currentUser?.mobileNumber ? `&tf_13137831594129=${currentUser?.mobileNumber}` : ""}`}
            target="_blank"
            rel="noreferrer"
            style={{
              textDecoration: "none",
              display: "inline-flex",
              alignItems: "center",
              color: theme.palette.neutral[600],
            }}
          >
            <MailOutlineIcon sx={{ mr: 2 }} />
            {t("common.createHelpTicket")}
          </a>
        </MenuItem>
      </Menu>
    </Box>
  );
}

export default HelpCenterMenu;
