import { Theme } from "@emotion/react";
import { SxProps } from "@mui/material";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import { forwardRef, MouseEventHandler, ReactNode, useMemo } from "react";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

interface ListItemLinkProps {
  disabled?: boolean;
  icon?: React.ReactElement;
  to: string;
  sx?: SxProps<Theme>;
  children?: ReactNode;
  disablePadding?: boolean;
  onClick?: MouseEventHandler<HTMLAnchorElement>;
}

export function ListItemLink({ icon, to, onClick, disabled = false, sx, children, ...rest }: ListItemLinkProps) {
  const renderLink = useMemo(
    () =>
      forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "to">>(function Link(itemProps, ref) {
        return <RouterLink to={disabled ? "#" : to} ref={ref} {...itemProps} role={undefined} />;
      }),
    [disabled, to]
  );

  return (
    <ListItem button component={renderLink} onClick={onClick} sx={sx} {...rest}>
      {icon ? <ListItemIcon>{icon}</ListItemIcon> : null}
      {children}
    </ListItem>
  );
}
