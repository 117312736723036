import { Alert, AlertTitle, Paper } from "@mui/material";
import { useSearchParams } from "react-router-dom";
import { RedeemSharingTokenForm } from "../../components/sharing/RedeemSharingTokenForm";
import { useI18n } from "../../hooks/useI18n";
import { PageLayout } from "../../layout/PageLayout";
import { useAuthenticatedUser } from "../../hooks";

export function RedeemSharingToken() {
  const [searchParams] = useSearchParams();

  const currentUser = useAuthenticatedUser();

  const { t } = useI18n();

  return (
    <PageLayout title={t("health_records.add_shared_hr")}>
      {currentUser ? (
        <Paper variant="outlined" sx={{ my: { xs: 3, md: 6 }, p: { xs: 2, md: 3 }, maxWidth: "900px" }}>
          <RedeemSharingTokenForm token={searchParams.get("token") as string} />
        </Paper>
      ) : (
        <Alert severity="info">
          <AlertTitle>{t("health_records.need_connection_title")}</AlertTitle>
          {t("health_records.need_connection")}
        </Alert>
      )}
    </PageLayout>
  );
}
