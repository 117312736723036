import { Apis } from "../network/apis";
import { GetVaccinationCertificateQuery } from "./getVaccinationCertificateQuery";

export interface Queries {
  getVaccinationCertificate: GetVaccinationCertificateQuery;
}

export const initQueries = ({ apis }: { apis: Apis }): Queries => {
  return {
    getVaccinationCertificate: new GetVaccinationCertificateQuery(apis.kairosCertApi)
  };
};
