import { FormControl, FormHelperText, InputLabel, Select, SelectChangeEvent, Typography } from "@mui/material";
import { ReactNode } from "react";
import { ErrorI18nObject, TranslateFunction } from "../../../utils/formUtils";
import { theme } from "../../layout/Theme";
import { BootstrapInput, StyledInput } from "./StyledInput";

export const StyledSelect = <T extends ReactNode>({
  label,
  name,
  id,
  fullWidth,
  error,
  errorMessage,
  disabled,
  value,
  required,
  placeholder,
  readOnly,
  children,
  onChange,
  onOpen,
  testId = "",
  renderValue,
  touched,
  translateErrors,
}: {
  label?: string;
  name: string;
  id?: string;
  fullWidth?: boolean;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  value?: T;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  children?: React.ReactNode;
  onChange?: ((event: SelectChangeEvent<T>, child: ReactNode) => void) | undefined;
  onOpen?: ((event: React.SyntheticEvent<Element, Event>) => void) | undefined;
  testId?: string;
  renderValue?: (value: T) => string | undefined;
  touched?: boolean;
  translateErrors?: TranslateFunction;
}) => {
  return !readOnly ? (
    <FormControl variant="standard" error={error} fullWidth={!!fullWidth} disabled={disabled} required={required}>
      {label && (
        <InputLabel
          shrink
          htmlFor={`${id || name}Input`}
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiInputLabel-asterisk": {
              color: theme.palette.error[500],
            },
          }}
          data-testid={`${testId || name || id}Label`}
        >
          {label}
        </InputLabel>
      )}
      <Select<T>
        id={`${id || name}Select`}
        value={value}
        onChange={onChange}
        onOpen={onOpen}
        displayEmpty
        renderValue={(selected) => {
          if (!selected || (typeof selected === "string" && selected.length === 0) || selected === "null") {
            return (
              <Typography color="rgba(0,0,0,0.87)" fontSize={14} sx={{ opacity: "0.42" }}>
                {placeholder}
              </Typography>
            );
          }
          return renderValue ? renderValue(selected) : selected;
        }}
        input={<BootstrapInput value={value} id={`${id || name}Input`} name={name} size="small" readOnly={readOnly} />}
        inputProps={{
          "data-testid": `${testId || name || id}SelectInput`,
        }}
        sx={{
          ".MuiInputBase-input:focus": {
            backgroundColor: "background.paper",
          },
        }}
        data-testid={`${testId || name || id}Select`}
      >
        {children}
      </Select>
      {error && errorMessage && touched && translateErrors && (
        <FormHelperText data-testid={`${testId}Error`}>
          {translateErrors(errorMessage as unknown as ErrorI18nObject)}
        </FormHelperText>
      )}
    </FormControl>
  ) : (
    <StyledInput
      id={id || name}
      fullWidth={!!fullWidth}
      size="small"
      value={renderValue && value ? renderValue(value) : ""}
      label={label}
      placeholder={placeholder}
      readOnly
      required={required}
      name={name}
      disabled={disabled}
      testId={testId || name || id}
    />
  );
};
