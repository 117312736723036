import { captureException, withScope } from '@sentry/react';
import React, { ContextType, ErrorInfo, ReactElement, ReactNode } from "react";
import { I18nContext, I18nInterface } from "../../providers/I18nProvider";

interface Props {
  children: ReactNode;
  fallback: FallbackComponent;
}

export type FallbackComponent = (props: {
  resetErrorBoundary(): void;
  i18n: I18nInterface | null;
}) => ReactElement;

interface State {
  componentStack: React.ErrorInfo["componentStack"] | null;
  error: Error | null;
  eventId: string | null;
}

const INITIAL_STATE = {
  componentStack: null,
  error: null,
  eventId: null,
};

export class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = INITIAL_STATE;
  }
  static contextType = I18nContext;
  declare context: ContextType<typeof I18nContext>;

  componentDidCatch(thrown: unknown, { componentStack }: ErrorInfo) {
    withScope((_scope) => {
      // Beware, anything can thrown in javascript! Let's convert the thrown
      // value to an error
      const error: Error = thrown instanceof Error ? thrown : new Error(String(thrown));

      // Create stack trace from componentStack param and links
      // to the original error using `error.cause` otherwise relies on error param for stacktrace.
      // Linking errors requires the `LinkedErrors` integration be enabled.
      const errorBoundaryError = new Error(error.message);
      errorBoundaryError.name = `React ErrorBoundary ${errorBoundaryError.name}`;
      errorBoundaryError.stack = componentStack ?? undefined;

      // Using the `LinkedErrors` integration to link the errors together.
      error.cause = errorBoundaryError;

      let eventId: string | null = null;
      if (import.meta.env.PROD) {
        eventId = captureException(error, { contexts: { react: { componentStack } } });
      }

      // componentDidCatch is used over getDerivedStateFromError
      // so that componentStack is accessible through state.
      this.setState({ error, componentStack, eventId });
    });
  }

  render(): JSX.Element {
    if (this.state.error) {
      return this.props.fallback({
        resetErrorBoundary: this.resetErrorBoundary,
        i18n: this.context,
      });
    } else {
      return <>{this.props.children}</>;
    }
  }

  public resetErrorBoundary: () => void = () => {
    this.setState(INITIAL_STATE);
  };
}
