import { Alert, Box, Snackbar } from "@mui/material";
import { useCallback, useState } from "react";
import { useParams } from "react-router-dom";
import { useHealthRecord } from "../../hooks/useHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { useKairosApi } from "../../hooks/useKairosApi";
import { HealthRecordForm, HealthRecordFormHandler } from "./HealthRecordForm";

export function EditHealthRecord() {
  const { t } = useI18n();

  const api = useKairosApi();

  const { id } = useParams() as { id: string };
  const { healthRecord } = useHealthRecord(id);

  const [errorDisplayed, setErrorDisplayed] = useState<boolean>(false);
  const [successDisplayed, setSuccessDisplayed] = useState<boolean>(false);

  const onSubmit: HealthRecordFormHandler = useCallback(
    (body, id) => {
      (async () => {
        try {
          const response = await api?.healthRecordApi.updateHealthRecord(id as string, body);

          if (response === undefined) {
            setSuccessDisplayed(false);
            setErrorDisplayed(true);
          } else {
            setErrorDisplayed(false);
            setSuccessDisplayed(true);
          }
        } catch (error) {
          setSuccessDisplayed(false);
          setErrorDisplayed(true);
        }
      })();
    },
    [api?.healthRecordApi]
  );

  if (healthRecord === undefined) {
    return null;
  }

  return (
    <>
      <HealthRecordForm onSubmit={onSubmit} healthRecord={healthRecord} />
      <Box sx={{ maxWidth: "900px", mt: 2 }}>
        <Snackbar
          open={errorDisplayed}
          autoHideDuration={6000}
          onClose={() => setErrorDisplayed(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            position: "static",
            transform: "none !important",
          }}
        >
          <Alert severity="error">{t("common.alerts.alert_classic")}</Alert>
        </Snackbar>
        <Snackbar
          open={successDisplayed}
          autoHideDuration={6000}
          onClose={() => setSuccessDisplayed(false)}
          anchorOrigin={{ vertical: "top", horizontal: "center" }}
          sx={{
            position: "static",
            transform: "none !important",
          }}
        >
          <Alert severity="success">{t("health_records.success")}</Alert>
        </Snackbar>
      </Box>
    </>
  );
}
