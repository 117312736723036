import { User as OidcClientUser, UserManager } from "oidc-client-ts";
import { AppStore } from "../store";
import { Service } from "./service";
import { AppRouter } from "../appConfig";

export class SignInService implements Service<void, void> {
  constructor(
    private readonly store: AppStore,
    private readonly window: Window,
    private readonly oidcManager: UserManager,
    private readonly router: AppRouter
  ) {}

  async call() {
    try {
      const oidcUser: OidcClientUser | null = await this.oidcManager.signinRedirectCallback();
      if (oidcUser) {
        // Clean up URL after successful signin, otherwise the page will infinitely
        // reload on page refresh
        const cleanedUpUrl = removeOpenIdConnectQueryParams(window.location.href);
        this.window.history.replaceState({}, this.window.document.title, cleanedUpUrl);
        this.router.state.location.search = cleanedUpUrl.search;

        try {
          this.store.setState({
            authState: {
              type: "signed-in",
              user: {
                id: oidcUser.profile.sub,
                name: oidcUser.profile.name ?? "",
                email: oidcUser.profile.email,
                mobileNumber: (oidcUser.profile as { mobile_number?: string }).mobile_number
              },
            },
          });

          console.debug("Logged in successfully:", oidcUser.profile);
        } catch (error) {
          console.debug("Error during parsing of server authentication data:", error);
          this.store.setState({ authState: { type: "error", error: error as Error } });
        }
      }
    } catch (error) {
      try {
        // Get rid of pre-existing invalid OpenID Connect query params, otherwise they will
        // take precedence on the new ones returned by the OpenID Connect server
        // and we will infinitely loop between this app and the OpenID Connect server
        await this.oidcManager.signinRedirect({
          redirect_uri: removeOpenIdConnectQueryParams(window.location.href).toString(),
        });
      } catch (signinError) {
        console.debug("Error during authentication:", signinError);
        this.store.setState({ authState: { type: "error", error: signinError as Error } });
      }
    }
  }
}

function removeOpenIdConnectQueryParams(href: string): URL {
  const newUrl = new URL(href);
  const params = new URLSearchParams(newUrl.search);
  params.delete("state");
  params.delete("code");
  params.delete("session_state");
  params.delete("iss");

  const search = params.toString();
  newUrl.search = search;
  return newUrl;
}
