import { createRoutesFromElements, Navigate, Route } from "react-router-dom";
import { Layout } from "./layout/Layout";
import { GlobalFallback } from "./components/error_handling/GlobalFallback";
import Homepage from "./pages/HealthRecords";
import { RedeemSharingToken } from "./pages/health_record/RedeemSharingToken";
import { NewHealthRecord } from "./pages/health_record/NewHealthRecord";
import { HealthRecord } from "./pages/HealthRecord";
import { VaccinationActs } from "./pages/health_record/VaccinationActs";
import AddVaccinationType from "./pages/health_record/AddVaccinationType";
import { AddVaccinationSimplified } from "./pages/health_record/AddVaccinationSimplified";
import { Shares } from "./pages/health_record/Shares";
import Share from "./pages/health_record/Share";
import { EditHealthRecord } from "./pages/health_record/EditHealthRecord";
import { Profile } from "./pages/health_record/profile/Profile";
import { Diagnostic } from "./pages/health_record/diagnostic/Diagnostic";
import { AccountManagement } from "./pages/AccountManagement";
import { TriggerTestError } from "./pages/TriggerTestError";
import { NotFound } from "./pages/NotFound";

export const routes = createRoutesFromElements(
  <Route path="/" element={<Layout />} errorElement={<GlobalFallback />}>
    <Route path="/" element={<Navigate to="/health-records" replace={true} />} />
    <Route path="/health-records" element={<Homepage />} />
    <Route path="/health-records/redeem-sharing-token" element={<RedeemSharingToken />} />
    <Route path="/health-records/new" element={<NewHealthRecord />} />
    <Route path="/health-records/:id" element={<HealthRecord />}>
      <Route path="" element={<VaccinationActs />} />
      <Route path="add-vaccination" element={<AddVaccinationType />} />
      <Route path="add-vaccination/multiple" element={<AddVaccinationType initialType="multiple" />} />
      <Route path="vaccination/:vaccinationActId" element={<AddVaccinationSimplified />} />
      <Route path="shares" element={<Shares />} />
      <Route path="share" element={<Share />} />
      <Route path="edit" element={<EditHealthRecord />} />
      <Route path="profile" element={<Profile />} />
      <Route path="diagnosis" element={<Diagnostic />} />
    </Route>
    <Route path="account" element={<AccountManagement />} />
    <Route path="/test-error" element={<TriggerTestError />} />
    <Route path="/not_found" element={<NotFound />} />
    <Route path="*" element={<Navigate to="/not_found" replace={true} />} />
  </Route>
);
