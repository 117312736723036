import { alpha, FormControl, FormHelperText, InputBase, InputBaseProps, InputLabel, styled } from "@mui/material";
import { ErrorI18nObject, TranslateFunction } from "../../../utils/formUtils";
import { theme } from "../../layout/Theme";

interface StyledInputProps extends InputBaseProps {
  label?: string;
  name: string;
  id?: string;
  fullWidth?: boolean;
  error?: boolean;
  errorMessage?: string;
  disabled?: boolean;
  value?: string | null;
  required?: boolean;
  placeholder?: string;
  readOnly?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement | HTMLTextAreaElement> | undefined;
  touched?: boolean;
  translateErrors?: TranslateFunction;
  testId?: string;
}
export const BootstrapInput = styled(InputBase)(({ theme }) => ({
  "label + &": {
    marginTop: theme.spacing(3),
  },
  "&.Mui-error": {
    ".MuiInputBase-input": {
      borderColor: theme.palette.error[600],
      background: theme.palette.error[100],
      color: theme.palette.error[400],
      borderRadius: 4,
      "&:focus": {
        boxShadow: `${alpha(theme.palette.error[600] as string, 0.25)} 0 0 0 0.2rem`,
        borderColor: theme.palette.error[600],
        borderRadius: 4,
      },
      ".MuiTypography-root": {
        color: theme.palette.error[400],
      },
    },
    svg: {
      color: theme.palette.error[400],
    },
  },
  "input[readonly]": {
    boxShadow: "none",
    borderColor: "#ced4da",
    cursor: "default",
    color: theme.palette.neutral[600],
    background: "none",
  },
  "& .MuiInputBase-input": {
    height: "22px",
    borderRadius: 4,
    position: "relative",
    border: "1px solid #ced4da",
    fontSize: 14,
    padding: "8px 16px",
    transition: theme.transitions.create(["border-color", "background-color", "box-shadow"]),
    "&:hover": {
      borderColor: theme.palette.neutral[500],
    },
    "&:focus": {
      boxShadow: `${alpha(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
      borderRadius: 4,
    },
    width: "100%",
  },
}));

export const StyledInput = ({
  label,
  name,
  id,
  fullWidth,
  error,
  errorMessage,
  disabled,
  value,
  required,
  placeholder,
  readOnly,
  onChange,
  touched,
  translateErrors,
  testId = "",
  ...rest
}: StyledInputProps) => {
  return (
    <FormControl
      variant="standard"
      error={!readOnly && error}
      fullWidth={!!fullWidth}
      disabled={disabled}
      required={required}
      data-testid={`${testId || name || id}FormControl`}
    >
      {label && (
        <InputLabel
          shrink
          htmlFor={id || name}
          sx={{
            color: theme.palette.neutral[600],
            fontSize: 14,
            fontWeight: 600,
            fontFamily: ["Open Sans", "sans-serif"].join(","),
            overflow: "hidden",
            whiteSpace: "nowrap",
            textOverflow: "ellipsis",
            transition: "color 0.2s ease-in-out",
            transform: "none",
            ".MuiInputLabel-asterisk": {
              color: theme.palette.error[500],
            },
            "&.Mui-focused": {
              color: readOnly ? theme.palette.neutral[600] : theme.palette.primary.main,
            },
          }}
          data-testid={`${testId || name || id}Label`}
        >
          {label}
        </InputLabel>
      )}
      <BootstrapInput
        {...rest}
        value={value}
        id={id || name}
        name={name}
        size="small"
        onChange={onChange}
        placeholder={placeholder}
        readOnly={readOnly}
        data-testid={`${testId || name || id}`}
        inputProps={{ "data-testid": `${testId || name || id}Input` }}
      />
      {!readOnly && error && errorMessage && touched && translateErrors && (
        <FormHelperText data-testid={`${testId || name || id}Error`}>
          {translateErrors(errorMessage as unknown as ErrorI18nObject)}
        </FormHelperText>
      )}
    </FormControl>
  );
};
