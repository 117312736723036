import AddIcon from "@mui/icons-material/Add";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import ShareIcon from "@mui/icons-material/Share";
import { Alert, Avatar, Box, Button, LinearProgress, List, Paper, Typography } from "@mui/material";
import { Link, useLocation, useParams } from "react-router-dom";
import { CitizenShareListItem } from "../../components/CitizenShareListItem";
import { PendingSharingRequestListItem } from "../../components/PendingSharingRequestListItem";
import { ProShareListItem } from "../../components/ProShareListItem";
import { EmptyListPlaceholder } from "../../components/shared/EmptyListPlaceholder";
import { TeamShareListItem } from "../../components/TeamShareListItem";
import { useHealthRecord } from "../../hooks/useHealthRecord";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";
import { useAuthenticatedUser } from "../../hooks";

const NoShares = () => {
  const { t } = useI18n();
  const { pathname } = useLocation();

  return (
    <EmptyListPlaceholder
      title={t("health_records.none_shared")}
      icon={<PersonAddIcon fontSize="large" sx={{ color: theme.palette.primary[500] }} />}
      button={
        <Button
          size="large"
          component={Link}
          to={pathname.replace("/shares", "/share")}
          variant="contained"
          startIcon={<ShareIcon />}
          disableElevation
          data-testid="share-health-record-button"
        >
          {t("health_records.share_button")}
        </Button>
      }
    >
      {t("health_records.share_msg")}
    </EmptyListPlaceholder>
  );
};

export function Shares() {
  const { t } = useI18n();

  const currentUser = useAuthenticatedUser();
  const { id: healthRecordId } = useParams() as { id: string };
  const { healthRecord, isLoading, error, refetch } = useHealthRecord(healthRecordId);

  const pendingSharingRequests = healthRecord?.sharingRequests.filter((req) => req.status == "PENDING");

  const { pathname } = useLocation();

  return (
    <>
      <Paper sx={{ overflow: "hidden", backgroundColor: "inherit" }} elevation={0}>
        {error && (
          <Typography sx={{ my: 4, mx: 2 }} align="center" component="div">
            <Alert severity="error">{t("common.alerts.alert_notification")}</Alert>
          </Typography>
        )}

        {isLoading && <LinearProgress variant="query" />}

        {!isLoading ? (
          healthRecord &&
          currentUser &&
          pendingSharingRequests &&
          (healthRecord.sharedWithTeams.length > 0 ||
            healthRecord.sharedWithPro.length > 0 ||
            healthRecord.sharedWithCitizens.filter((cit) => cit.id != currentUser.id)?.length > 0 ||
            pendingSharingRequests.length > 0) ? (
            <Box>
              <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
                <Button
                  startIcon={
                    <Avatar
                      sx={{
                        background: theme.palette.primary[500],
                        width: { xs: 24, md: 32 },
                        height: { xs: 24, md: 32 },
                      }}
                    >
                      <AddIcon fontSize="small" sx={{ color: "white" }} />
                    </Avatar>
                  }
                  sx={{
                    ".MuiButton-startIcon": {
                      minWidth: { xs: "40px", md: "56px" },
                      m: 0,
                    },
                    p: 2,
                  }}
                  component={Link}
                  to={pathname.replace("/shares", "/share")}
                  disableElevation
                  data-testid="share-health-record-button"
                >
                  <Typography color="primary" fontWeight="500">
                    {t("health_records.share_button_2")}
                  </Typography>
                </Button>
              </Box>
              <List sx={{ width: "100%", mb: 2 }}>
                {healthRecord.sharedWithTeams.map((team) => (
                  <TeamShareListItem key={team.id} healthRecord={healthRecord} team={team} refetch={refetch} />
                ))}

                {healthRecord.sharedWithPro.map((pro) => (
                  <ProShareListItem key={pro.id} healthRecord={healthRecord} pro={pro} refetch={refetch} />
                ))}

                {healthRecord.sharedWithCitizens
                  .filter((cit) => cit.id != currentUser.id)
                  .map((citizen) => (
                    <CitizenShareListItem
                      key={citizen.id}
                      healthRecord={healthRecord}
                      citizen={citizen}
                      refetch={refetch}
                    />
                  ))}

                {pendingSharingRequests?.map((sharingRequest) => (
                  <PendingSharingRequestListItem
                    sharingRequest={sharingRequest}
                    refetch={refetch}
                    key={sharingRequest.id}
                  />
                ))}
              </List>
            </Box>
          ) : (
            <NoShares />
          )
        ) : null}
      </Paper>
    </>
  );
}
