import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import { LinearProgress, Link, Typography } from "@mui/material";
import { DiagnosticResults } from "@syadem/sad-ui";
import { PatientProfile } from "@syadem/sad-ui/dist/domain/PatientProfile";
import { Link as RouterLink, useParams } from "react-router-dom";
import { useDaphne } from "../../../hooks/useDaphne";
import { useDiagnostic } from "../../../hooks/useDiagnostic";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useI18n } from "../../../hooks/useI18n";
import { theme } from "../../../layout/Theme";

export const MatchingCondition = ({
  conditionId,
  conditionLabelWithValue,
  testId,
}: {
  conditionId: string;
  conditionLabelWithValue: string;
  testId?: string;
}) => {
  const { id } = useParams() as { id: string };
  return (
    <Link
      component={RouterLink}
      to={`/health-records/${id}/profile`}
      data-testid={testId ? `${testId}-matchingCondition${conditionId}` : undefined}
      underline="none"
      sx={{
        display: "flex",
        alignItems: "center",
        "&:hover": {
          color: theme.palette.primary[400],
        },
      }}
    >
      <FavoriteBorderIcon sx={{ mr: 1, fontSize: "16px" }} />
      <Typography variant="body2" textAlign="justify">
        {conditionLabelWithValue}
      </Typography>
    </Link>
  );
};

export function Diagnostic() {
  const { id } = useParams() as { id: string };
  const { healthRecord } = useHealthRecord(id);
  const { diagnostic } = useDiagnostic(healthRecord);
  const daphne = useDaphne();
  const { t, locale } = useI18n();

  return (
    <>
      {diagnostic && daphne ? (
        <DiagnosticResults
          diagnostic={diagnostic}
          // We are forced to cast here because kairos-proto does not provide a proper type
          // for healthRecord.profile
          profile={healthRecord?.profile as PatientProfile}
          vaccinations={healthRecord?.vaccinationActs}
          daphne={daphne}
          locale={locale}
          injectedComponents={{
            MatchingConditionComponent: MatchingCondition,
          }}
          publicDestination="patient"
          infosMessage={t("recommandations.DiagnosticList.infosMessage")}
        />
      ) : (
        <LinearProgress variant="query" />
      )}
    </>
  );
}
