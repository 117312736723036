import { Box, CircularProgress, Container } from "@mui/material";
import { ConditionProfile, ProfileActionType, ProfileState, reducer } from "@syadem/sad-ui";
import { useEffect, useReducer, useState } from "react";
import { useParams } from "react-router-dom";
import { useHealthRecord } from "../../../hooks/useHealthRecord";
import { useI18n } from "../../../hooks/useI18n";
import { useKairosApi } from "../../../hooks/useKairosApi";
import { useProfilQuestionnaire } from "../../../hooks/useProfilQuestionnaire";
import { Notes } from "../note/Notes";
import ProfileConditions from "./ProfileConditions";
import ProfileQuestionnaire from "./ProfileQuestionnaire";

export function Profile() {
  const { id } = useParams() as { id: string };
  const { healthRecord } = useHealthRecord(id);
  const { questionnaireSections } = useProfilQuestionnaire(healthRecord);
  const { t } = useI18n();
  const api = useKairosApi();
  const [profileIsLoading, setProfileIsLoading] = useState(true);
  const initialState: ProfileState = {
    breadcrumb: [
      {
        level: 0,
        sectionId: "root",
        name: t("profile.shortTitle"),
      },
    ],
    currentSectionId: "root",
    searchConditionTerm: "",
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    if (questionnaireSections && healthRecord?.profile) {
      //init state of reducer with profile conditions just one time
      const reducedConditions = Object.entries(healthRecord?.profile).reduce(
        (acc: ConditionProfile[], [key, value]) => [
          ...acc,
          {
            id: key,
            value: value.value,
          },
        ],
        [],
      );

      //Update state of the reducer with the conditions
      dispatch({
        type: ProfileActionType.LoadQuestionnaireAndConditions,
        payload: { sections: questionnaireSections, conditionsProfile: reducedConditions },
      });
    }
    setProfileIsLoading(false);
  }, [healthRecord?.profile, questionnaireSections]);

  const setCondition = async (value: string | Date | number | boolean | null | undefined, conditionId: string) => {
    const newValue = value === "" ? null : value;
    await api?.healthProfile?.setCondition(healthRecord?.id as string, {
      conditionId,
      value: newValue,
    });
  };

  return (
    <Container maxWidth="lg" disableGutters>
      {profileIsLoading ? (
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            width: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: "flex", flexDirection: "column", maxWidth: "900px" }}>
          {healthRecord && healthRecord.notes.length > 0 && <Notes />}
          <Box sx={{ flex: 1 }}>
            <ProfileConditions state={state} dispatch={dispatch} />
          </Box>
          <ProfileQuestionnaire state={state} dispatch={dispatch} setCondition={setCondition} />
        </Box>
      )}
    </Container>
  );
}
