import { ApiClient } from "@syadem/ariane-js";
import { useContext } from "react";
import { DependenciesContext } from "../providers/DependenciesProvider";

export function useArianeApi(): ApiClient {
  const dependencies = useContext(DependenciesContext);
  if (!dependencies) {
    throw new Error("Dependencies container is null, did you forget to pass it to the provider?");
  } else {
    return dependencies.arianeApi;
  }
}
