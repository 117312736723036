import ClearIcon from "@mui/icons-material/Clear";
import PersonIcon from "@mui/icons-material/Person";
import { Avatar, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { Citizen, HealthRecord, UnshareBodyAccountTypeEnum } from "@syadem/kairos-citizen-js";
import { useI18n } from "../hooks/useI18n";
import { useKairosApi } from "../hooks/useKairosApi";
import { theme } from "../layout/Theme";
import { ListItemDeleteButton } from "./shared/ListItemDeleteButton";

interface CitizenShareListItemProps {
  healthRecord: HealthRecord;
  citizen: Citizen;
  refetch: () => void;
}

export function CitizenShareListItem(props: CitizenShareListItemProps) {
  const { healthRecord, citizen, refetch } = props;
  const api = useKairosApi();
  const { t } = useI18n();

  return (
    <ListItem
      sx={{
        px: 3,
        py: 2,
        mb: 1,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
      }}
      secondaryAction={
        healthRecord.ownerId !== citizen.id &&
        api && (
          <ListItemDeleteButton
            deleteRecord={() => {
              return api.healthRecordApi.unshare(healthRecord.id, {
                account: {
                  id: citizen.id,
                  type: UnshareBodyAccountTypeEnum.Citizen,
                },
              });
            }}
            refreshCallback={refetch}
            title={t("health_records.revoke")}
            modalTitle={t("health_records.revoke_access")}
            modalContent={
              <p>
                {t("health_records.revoke_access_start")}
                <Typography component="span" fontWeight="bold">
                  {citizen.email}
                </Typography>
                {t("health_records.revoke_access_end_citizen")}
              </p>
            }
            icon={<ClearIcon fontSize="small" color="error" />}
            variant="outlined"
            buttonWidth="110px"
          />
        )
      }
    >
      <ListItemAvatar>
        <Avatar sx={{ background: theme.palette.primary[100] }}>
          <PersonIcon sx={{ color: theme.palette.primary[500] }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Typography fontWeight="500" color="#2B3A5C">
            {citizen.email}
          </Typography>
        }
        secondary={t("users.citizen")}
      />
    </ListItem>
  );
}
