import { Box, Typography } from "@mui/material";
import { HealthRecord } from "@syadem/kairos-citizen-js";
import { Conclusion, Diagnostic } from "@syadem/sad-js";
import { orderBy } from "lodash-es";
import { calculateAge, utcToday } from "../../../utils/ageCalculator";
import { dayjs } from "../../../utils/dayjs";
import { useI18n } from "../../hooks/useI18n";
import { theme } from "../../layout/Theme";

import Divider from '@mui/material/Divider';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import Skeleton from '@mui/material/Skeleton';
import Alert from '@mui/material/Alert';

interface HealthRecordListItemContentProps {
  healthRecord: HealthRecord;
  testId?: string;
  diagnosticIsLoading: boolean;
  diagnostic?: Diagnostic;
}

export default function HealthRecordListItemContent({
  healthRecord,
  testId,
  diagnosticIsLoading,
  diagnostic,
}: HealthRecordListItemContentProps) {
  const { t } = useI18n();

  return (
    <>
      <Box
        sx={{
          overflow: "hidden",
          textOverflow: "ellipsis",
          whiteSpace: "nowrap",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          width: "100%",
          position: "relative",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            flexDirection: "column",
            textOverflow: "ellipsis",
            overflow: "hidden",
            whiteSpace: "nowrap",
            width: "100%",
          }}
          component="span"
        >
          <Box sx={{ p: { xs: 1, md: 2 }, display: "flex", alignItems: "flex-start", flexDirection: "column", width: "100%" }}>
            <Typography
              fontWeight="500"
              variant="h6"
              color={theme.palette.neutral[600]}
              data-testid={`${testId}FirstNames`}
              sx={{
                fontSize: '18px',
              }}
            >
              {`${healthRecord.firstNames[0].toUpperCase() + healthRecord.firstNames.substring(1)}`}
            </Typography>
            <Divider variant="fullWidth" style={{width:'100%', margin:'0rem 0 0.2rem 0'}}/>
            <Typography
              variant="body2"
              color={theme.palette.neutral[500]}
              component="span"
              data-testid={`${testId}Birthdate`}
              sx={{
                whiteSpace: "normal",
              }}
            >
              {`${calculateAge(utcToday(), healthRecord.birthDate, true, t)}`}
            </Typography>
            <Box
              sx={{
                display: "inline-flex",
                justifyContent: "center",
                width: "100%",
                visibility: healthRecord.externalId ? "visible" : "hidden",
              }}
            >
              <Typography
                variant="body2"
                color={theme.palette.neutral[500]}
                component="span"
                data-testid={`${testId}ExternalId`}
              >
              </Typography>
            </Box>
          </Box>
          <DiagnosticSummary diagnostic={diagnostic} isLoading={diagnosticIsLoading} />
        </Box>
      </Box>
    </>
  );
}

export type ConclusionExtended = Conclusion | "to_do_asap" | "to_do_later";

function DiagnosticSummary({ diagnostic, isLoading }: { diagnostic?: Diagnostic; isLoading: boolean }) {
  const { t, locale } = useI18n();
  const getWarningMessage = (diagnostic: Diagnostic) => {
    const hasSpecialSituation = diagnostic.diagnostic_per_disease.some(
      (diag) => diag.advice.conclusion === Conclusion.Exception || diag.advice.conclusion === Conclusion.Unmanaged
    );
    const hasContraindication = diagnostic.diagnostic_per_disease.some(
      (diag) => diag.advice.conclusion === Conclusion.Contraindicated
    );

    return (
      <Box
        mt={"auto"}
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: "column",
          gap: "1px",
          paddingBottom: "-10px",
          // backgroundColor: "red"
        }}
      >
        {hasSpecialSituation && (
          <Box
            sx={{
              width: "auto",
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "100%",
              whiteSpace: "normal",
              // backgroundColor: "blue",
            }}
          >
            <Typography variant="body2" paragraph={true} color="common.black" sx={{marginBottom: "-0.1rem"}}>
              &bull; {t("diagnostic.specialCase")}
            </Typography>
          </Box>
        )}
        {hasContraindication && (
          <Box
            sx={{
              width: "auto",
              display: "flex",
              flexWrap: "wrap",
              maxWidth: "100%",
              whiteSpace: "normal",
              // backgroundColor: "green",
            }}
          >
            <Typography variant="body2" paragraph={true} color="common.black" sx={{marginBottom: "-0.1rem"}}>
              &bull; {t("diagnostic.contraindicated")}
            </Typography>
          </Box>
        )}
      </Box>
    );
  };

  const getCustomStatus = (diagnostic: Diagnostic) => {
    if (["to_do_asap", Conclusion.Late].includes(diagnostic.conclusion)) {
      return (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            p: 2,
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "flex-start",
            marginTop: "-1.3rem",
          }}
        >
          <Box
            sx={{
              backgroundColor: theme.palette.error.main,
              width: "95%",
              height: "35px",
              display: "flex",
              alignItems: "center",
              marginLeft: "-1rem",
              borderTopRightRadius: 4,
              borderBottomRightRadius: 4,
              '@media screen and (max-width: 1000px)': {
                marginBottom: "1rem",
              },
              '@media screen and (max-width: 660px)': {
                marginBottom: "1rem",
              },
              '@media screen and (max-width: 450px)': {
                marginBottom: "1rem",
              },
            }}
          >
            <ErrorOutlineIcon sx={{ mr: 1, height: 23, width: 23, color: "white", marginLeft: "1rem" }} />
            <Typography fontWeight={600} variant="h6">
              {t("diagnostic.conclusion.late")}
            </Typography>
          </Box>
          {getWarningMessage(diagnostic)}
        </Box>
      );
    } else if (diagnostic.conclusion === Conclusion.Todo) {
      const diagnosticsPerDisease = diagnostic.diagnostic_per_disease.filter(
        (diag) => diag.advice.conclusion === diagnostic.conclusion
      );
      const diagnosticsPerDiseaseSorted = orderBy(diagnosticsPerDisease, (diag) => diag.advice.targeted_date, "asc");
      const nextTargetedDate = diagnosticsPerDiseaseSorted[0].advice.targeted_date;
      const monthsDiff = dayjs(nextTargetedDate).diff(dayjs(), "month");

      if (monthsDiff <= 6) {
        return (
          <Box
            sx={{
              height: "100%",
              width: "100%",
              p: 2,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "flex-start",
              marginTop: "-1.3rem",
            }}
          >
            <Box
              sx={{
                backgroundColor: theme.palette.warning.main,
                width: "95%",
                height: "35px",
                display: "flex",
                alignItems: "center",
                marginLeft: "-1rem",
                marginBottom: "0.8rem",
                borderTopRightRadius: 4,
                borderBottomRightRadius: 4,
                '@media screen and (max-width: 1000px)': {
                  marginBottom: "1rem",
                },
                '@media screen and (max-width: 660px)': {
                  marginBottom: "1rem",
                },
                '@media screen and (max-width: 450px)': {
                  marginBottom: "1rem",
                },
              }}
            >
              <WarningAmberIcon sx={{ mr: 1, height: 23, width: 23, color: "white", marginLeft: "1rem", lineHeight: "0.5rem" }} />
              <Typography fontWeight={600} variant="h6">
                {t("diagnostic.conclusion.to_do")}
              </Typography>
            </Box>
            {getWarningMessage(diagnostic)}
            <Alert
              severity="info"
              icon={false}
              sx={{
                width: "auto",
                display: "flex",
                flexWrap: "wrap",
                maxWidth: "120%",
                whiteSpace: "normal",
                marginTop: "0.5rem",
              }}
            >
              {diagnosticsPerDiseaseSorted && diagnosticsPerDiseaseSorted.length > 0 && (
                <Typography variant="inherit" paragraph={true} sx={{ marginBottom: "-0.5rem", marginTop: "-0.5rem"}}>
                  {t("diagnostic.nextVaccination", {
                    targetedDate: `${dayjs(nextTargetedDate).locale(locale).format("L")}`,
                  })}
                </Typography>
              )}
            </Alert>

          </Box>
        );
      }
    }

    return (
      <Box
        sx={{
          height: "100%",
          width: "100%",
          p: 2,
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          justifyContent: "flex-start",
          marginTop: "-1.3rem",
        }}
      >
        <Box
          sx={{
            backgroundColor: theme.palette.success.main,
            width: "95%",
            height: "35px",
            display: "flex",
            alignItems: "center",
            marginLeft: "-1rem",
            borderTopRightRadius: 4,
            borderBottomRightRadius: 4,
            '@media screen and (max-width: 1000px)': {
              marginBottom: "1rem",
            },
            '@media screen and (max-width: 660px)': {
              marginBottom: "1rem",
            },
            '@media screen and (max-width: 450px)': {
              marginBottom: "1rem",
            },
          }}
        >
          <CheckCircleOutlineIcon sx={{ mr: 1, height: 23, width: 23, color: "white", marginLeft: "1rem" }} />
          <Typography fontWeight={600} variant="h6">
            {t("diagnostic.conclusion.up_to_date")}
          </Typography>
          </Box>
          {getWarningMessage(diagnostic)}
      </Box>
    );
  };

  return !isLoading && diagnostic ? (
    <Box
      sx={{
        minHeight: 130,
        width: "100%",
        color: "white",
        fontWeight: 600,
      }}
    >
      {getCustomStatus(diagnostic)}
    </Box>
  ) : (
    <Box
      sx={{
        backgroundColor: theme.palette.neutral.main,
        p: 3,
        minHeight: 100,
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          aligntItems: "flex-start",
          justifyConten: "flex-start",
          gab: "1rem",
          width: "100%"
        }}
      >
        <Skeleton variant="rectangular" width={170} height={44} sx={{ marginLeft: "-1.5rem", marginTop: "-0.5rem", marginBottom: "1rem" }} />
        <Box>
          <Skeleton variant="text" sx={{ fontSize: "0.8rem", width: "84%", marginLeft: "-1rem" }} />
          <Skeleton variant="text" sx={{ fontSize: "0.8rem", width: "84%", marginLeft: "-1rem" }} />
        </Box>
      </Box>
    </Box>
  );
}
