import { Box, Typography } from "@mui/material";
import { Professional, Team } from "@syadem/ariane-js";
import { Daphne } from "@syadem/daphne-js";
import { VaccinationAct } from "@syadem/kairos-citizen-js";
import { getTranslatedFieldByLocale } from "../../utils/translatedFields";
import { useDiseasesByVaccine } from "../hooks/useDiseasesByVaccine";
import { useI18n } from "../hooks/useI18n";
import { theme } from "../layout/Theme";

interface VaccineProps {
  vaccineId: string;
  vaccinationAct: VaccinationAct;
  daphne: Daphne;
  certificator: Professional | Team | undefined;
  displayDiseases?: boolean;
}

export function Vaccine(props: VaccineProps) {
  const { vaccineId, daphne, displayDiseases } = props;
  const vaccine = daphne.repositories.vaccines.find(vaccineId);
  const { diseases } = useDiseasesByVaccine(vaccine);
  const { locale } = useI18n();

  return (
    <>
      {vaccine && (
        <>
          <Box display="flex" sx={{ alignItems: "center" }}>
            <Typography fontWeight="500" color={theme.palette.neutral[600]}>
              {getTranslatedFieldByLocale(locale, vaccine.name)}
            </Typography>
          </Box>
          {displayDiseases && diseases && (
            <Typography color={theme.palette.neutral[600]}>
              {diseases.map((disease) => getTranslatedFieldByLocale(locale, disease.name)).join(", ")}
            </Typography>
          )}
        </>
      )}
    </>
  );
}
