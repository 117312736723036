import { SadvApi } from '@syadem/sad-js';
import { useContext } from "react";
import { DependenciesContext } from '../providers/DependenciesProvider';

export function useSad(): SadvApi {
  const dependencies = useContext(DependenciesContext);
  if (!dependencies) {
    throw new Error("Dependencies container is null, did you forget to pass it to the provider?");
  } else {
    return dependencies.sadApi;
  }
}
