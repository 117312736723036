import ClearIcon from "@mui/icons-material/Clear";
import WatchLaterIcon from "@mui/icons-material/WatchLater";
import { Avatar, Box, Chip, ListItem, ListItemAvatar, ListItemText, Typography } from "@mui/material";
import { SharingRequest } from "@syadem/kairos-citizen-js";
import { capitalize } from "lodash-es";
import { useI18n } from "../hooks/useI18n";
import { useKairosApi } from "../hooks/useKairosApi";
import { theme } from "../layout/Theme";
import IconDoctor from "./icons/IconDoctor";
import { ListItemDeleteButton } from "./shared/ListItemDeleteButton";

interface PendingSharingRequestListItemProps {
  sharingRequest: SharingRequest;
  refetch: () => void;
}

export function PendingSharingRequestListItem(props: PendingSharingRequestListItemProps) {
  const { sharingRequest, refetch } = props;
  const api = useKairosApi();
  const { t } = useI18n();

  return (
    <ListItem
      sx={{
        px: 3,
        py: 2,
        mb: 1,
        border: `solid 1px ${theme.palette.neutral[200]}`,
        borderRadius: 1,
        bgcolor: "background.paper",
      }}
      secondaryAction={
        api && (
          <ListItemDeleteButton
            deleteRecord={() =>
              api.sharingRequestApi.deleteSharingRequest(sharingRequest.healthRecordId, sharingRequest.id)
            }
            refreshCallback={refetch}
            title={t("common.cta.cancel")}
            modalTitle={t("health_records.cancel_invite")}
            modalContent={t("health_records.cancel_invite_validation")}
            icon={<ClearIcon fontSize="small" color="error" />}
            variant="outlined"
            buttonWidth="110px"
          />
        )
      }
    >
      <ListItemAvatar>
        <Avatar sx={{ background: theme.palette.primary[100] }}>
          <IconDoctor color={theme.palette.primary[500]} style={{ height: "20px" }} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={
          <Box display="flex" sx={{ alignItems: "center" }}>
            <Typography fontWeight="500" color="#2B3A5C">
              {`${capitalize(sharingRequest.toPro.firstName)} ${capitalize(sharingRequest.toPro.lastName)}`}
            </Typography>
            <Chip
              label={t("sharing_requests.pending_requests")}
              color="info"
              size="small"
              sx={{ ml: 1, mt: "-4px", border: `1px solid ${theme.palette.primary[200]}` }}
              icon={<WatchLaterIcon fontSize="small" />}
            />
          </Box>
        }
        secondary={t("users.pro")}
      />
    </ListItem>
  );
}
